import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet  
} from "react-router-dom";
import NaslovnaProjektovanje from "./pages/projektovanje/Naslovna";
import { useSelector, useDispatch } from 'react-redux';
import { prijava } from './autentikacija-redux/korisnik/korisnik_slice';
import { useEffect,useState } from "react";
import Registracija from "./pages/Registracija";
import Topbar from "./components/Topbar";
import Prijava from "./pages/Prijava";
import Navbar from "./components/Navbar";
import Onama from "./pages/izgradnja/Onama";
import FooterIzgradnja from "./pages/izgradnja/Footer";
import IzmijeniProjekat from "./pages/admin/IzmijeniProjekat";
import Footer from "./components/Footer";
import Breadcrumb from "./pages/izgradnja/Breadcrumb";
import { Clanak } from "./pages/izgradnja/Clanak";
import DodajProjekat from "./pages/admin/DodajProjekat"
import { Audio } from "react-loader-spinner";
import ProjektiKontakt from "./pages/izgradnja/Kontakt";
import Naslovna from "./pages/Naslovna";
import Navbar2 from "./components/Navbar2";
import Admin from "./pages/admin/Admin";
import Clanci from "./pages/admin/Clanci";
import DodajClanak from "./pages/admin/dodajClanak";
import IzmijeniClanak from "./pages/admin/IzmijeniClanak";
import Projekti from "./pages/admin/Projekti";
import {Projekti as ProjektiIzgradnja} from "./pages/izgradnja/Projekti";
import HeaderIzgradnja  from "./pages/izgradnja/Header";
import NaslovnaIzgradnja  from "./pages/izgradnja/Naslovna";
import Projekat from "./pages/izgradnja/Projekat";
import { Blog } from "./pages/izgradnja/Blog";

const Layout = ()=>{
  return(
    <>
    <Navbar2/>
    <Outlet />
    
    </>
  )
}
const Layout1 = ()=>{
  return(
    <>
    <Navbar/>
    <Outlet />
    <Footer/>
    
    </>
  )
}

const LayoutIzgradnja = ()=>{
  return(
    <>
    <HeaderIzgradnja/>
    <Outlet />
    <FooterIzgradnja/>
    
    </>
  )
}
//ruter za poddomen izgradnja
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout1/>,
    children:[
      {path:'/',
    element:<Naslovna/>,}
    ]
  },
  {
  path: "/",
  element: <Layout/>,
  children:[{
    path:"/administrator",
    element:<Admin/>,
  children:[   {
    path:"clanci",
    element:<Clanci/>,
      },
      {
        path:"clanci/dodaj-clanak",
        element:<DodajClanak/>,
          },
          {path:"clanci/izmijeni-clanak",
        element:<IzmijeniClanak/>},
      {
    path:"projekti",
    element:<Projekti/>,
      },
      {
        path:"projekti/dodaj-projekat",
        element:<DodajProjekat/>,
          },
          {path:"projekti/izmijeni-projekat",
        element:<IzmijeniProjekat/>}
    ]},
    
   
          {
            path: "/administrator/registracija",
            element: <Registracija/>,
          },
          {
            path: "/administrator/prijava",
            element: <Prijava/>,
          },
          {
            path: "/administrator/prijava",
            element: <Prijava/>,
          },
        ]}]);


        const routerIzgradnja = createBrowserRouter([
          {
            path: "/",
            element: <LayoutIzgradnja/>,
            children:[
              {path:'/',
            element:    <NaslovnaIzgradnja/>
          },
          {path:'/clanci',
            element:        <><Breadcrumb/>
            <Blog/></>
          },
          {path:"/clanci/:clanak",
        element:<><Breadcrumb/><Clanak/></>},
          {path:'/projekti',
            element:        <><Breadcrumb/>
            <ProjektiIzgradnja/></>
          },
          {path:'/projekti/:nazivprojekta',
        element: <><Breadcrumb/><Projekat/></>},
        {path:'/:nazivprojekta',
        element: <><Breadcrumb/><Projekat/></>}
        ,{path:'/kontakt',
        element:        <><Breadcrumb/>
        <ProjektiKontakt/></>
      },
      {path:'/o-nama',
        element:        <><Breadcrumb/>
        <Onama/></>
      },
            ]
          },
        ]);
        const routerProjektovanje = createBrowserRouter([
          {
            path: "/",
            element: <NaslovnaProjektovanje/>,
           
          },
        ]);
        const routerNekretnine = createBrowserRouter([
          {
            path: "/",
            element: <naslovna/>,
            children:[
              {path:'/',
            element:<h1>Nekretnine</h1>},
            ]
          },
        ]);



function App() {
  const [loader, postaviLoader] = useState(false);

  const korisniks = useSelector((state) => state.korisnik)
  const dispatch = useDispatch();
  let korisnik=localStorage.getItem('korisnik');
  useEffect(()=>{dispatch(prijava(korisnik));postaviLoader(true)},[])
  if(loader==false){
    return <Audio
    height="80"
    width="80"
    radius="9"
    color="green"
    ariaLabel="loading"
    wrapperStyle
    wrapperclassName
  />
  }
  else{
  return (
    <div className="app">
      <div className="cont">
<RouterProvider router={window.location.host.split('.')[0] == 'izgradnja' ? routerIzgradnja : window.location.host.split('.')[0] == 'projektovanje' ? routerProjektovanje : window.location.host.split('.')[0] === 'nekretnine' ? routerNekretnine : router }/>    </div>
</div>
  );
}}


export default App;
