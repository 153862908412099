import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNewspaper,
  faBriefcase,
  faChartSimple,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function Sidebarr() {
  const [aktivan, postaviAktivan] = useState([false, false, false]);

  const promjena = (e, kljuc) => {
    let niz = [...aktivan];
    niz = niz.map((elem) => (elem = false));
    niz[kljuc] = true;

    postaviAktivan(niz);
  };
  return (
    <Sidebar>
      <Menu>
        <MenuItem
          onClick={(event) => promjena(event, 0)}
          active={aktivan[0]}
          routerLink={<Link to="/administrator/clanci" />}
        >
          <FontAwesomeIcon className="margina" icon={faChartSimple} /> Analitika
        </MenuItem>
        <SubMenu
          label={
            <div>
              <FontAwesomeIcon className="margina" icon={faNewspaper} />
              <span>Članci</span>
            </div>
          }
        >
          <MenuItem
            onClick={(event) => promjena(event, 1)}
            active={aktivan[1]}
            routerLink={<Link to="/administrator/clanci" />}
            className="pod-meni"
          >
            Svi članci
          </MenuItem>
          <MenuItem
            className="pod-meni"
            routerLink={<Link to="/administrator/clanci/dodaj-clanak" />}
          >
            Dodaj novi članak
          </MenuItem>
        </SubMenu>
        <SubMenu
          label={
            <div>
              <FontAwesomeIcon className="margina" icon={faBriefcase} />
              <span>Projekti</span>
            </div>
          }
        >
          <MenuItem
            onClick={(event) => promjena(event, 2)}
            active={aktivan[2]}
            routerLink={<Link to="/administrator/projekti" />}
            className="pod-meni"
          >
            Svi projekti
          </MenuItem>
          <MenuItem
            className="pod-meni"
            routerLink={<Link to="/administrator/projekti/dodaj-projekat" />}
          >
            Dodaj novi projekat
          </MenuItem>
        </SubMenu>{" "}
      </Menu>
    </Sidebar>
  );
}
