import React from "react";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { LineWave } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAlert, Provider } from "react-alert";
import AlertMUITemplate from "react-alert-template-mui";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import { postaviUnosss } from "../../projekat-redux/dodajClanak";
import Col from "react-bootstrap/Col";
import Parser from "html-react-parser";

import axios from "axios";

const paginacija = {
  rowsPerPageText: "Redova po stranici",
  rangeSeparatorText: "od",
  selectAllRowsItem: true,
};
const podacii = async () => {
  return await axios({
    method: "get",
    url: "https://white-homes.me/clanci",
  });
};
export default function Clanci() {
  const [podaci, postaviPodatke] = useState([]);
  const alert = useAlert();
  const clanci = useSelector((state) => state.clanci);
  const dispatch = useDispatch();
  const [loader, postaviLoader] = useState(false);
  const [red, postaviRed] = useState();
  useEffect(() => {
    if (podaci.length == 0) {
      podacii().then((rez, greska) => {
        console.log(rez);
        if (rez) {
          postaviPodatke(rez.data);
          postaviLoader(true);
          console.log(podaci);
        } else {
          console.log(greska);
        }
      });
    }
  }, []);

  const klikRed = (x) => {
    postaviRed(x);
    console.log(red);
  };
  async function brisiClanak(niz, id) {
    const elementBrisemo = niz.findIndex((element) => element.id_clanci === id);
    if (elementBrisemo > -1) {
      niz.splice(elementBrisemo, 1);
      postaviPodatke([...niz]);
      let odgovor = await axios.post("https://white-homes.me/brisi-clanak", {
        id,
      });
      console.log(odgovor);

      return "bravo";
    }
    return "greska";
  }
  const izmijeniProjekat = (red) => {
    console.log(red);
    dispatch(postaviUnosss(red));
  };
  const alertFja = (red) => {
    console.log(red);
    alert.show("Da li ste sigurni da želite da obrižete izabrani projekat?", {
      title: "Upozorenje!",

      actions: [
        {
          copy: "Da, siguran sam",
          onClick: () => {
            brisiClanak(podaci, red["id_clanci"]);
          },
        },
        {
          copy: "Ne, Izađi",
          onClick: () => {},
        },
      ],
    });
  };

  /*useEffect(()=>{
      if(clanci)
      console.log(clanci);
    },[clanci]);*/
  const Kolone = [
    {
      name: "ID",
      selector: (row) => <div style={{ textAlign: "center" }}>{row.id}</div>,
      width: "5%",
    },
    {
      name: "Naziv",
      selector: (row) => (
        <div style={{ textAlign: "center" }}>
          <strong>{row.naziv}</strong>{" "}
        </div>
      ),
      width: "20%",
    },
    {
      name: "Opis",
      selector: (row) => (
        <div className="editable" disabled>
          {Parser(row.tekst)}
        </div>
      ),
      width: "35%",
    },
    {
      name: "Datum",
      selector: (row) => <div style={{ textAlign: "center" }}>{row.datum}</div>,
      width: "10%",
    },
    {
      name: "Slika",
      selector: (row) => (
        <div style={{ textAlign: "center" }}>
          <img
            style={{
              height: 100,
              width: "auto",
              padding: "20px 0px",
              textAlign: "center !important",
            }}
            src={row.odabrana_slika}
          />
        </div>
      ),
      width: "15%",
    },
    {
      name: "Akcije",
      selector: (row) => (
        <div style={{ textAlign: "center" }}>
          <span style={{ marginRight: 10, cursor: "pointer" }}>
            <Link to="izmijeni-clanak">
              <FontAwesomeIcon
                onClick={() => {
                  izmijeniProjekat(row);
                }}
                color="green"
                icon={faEdit}
              />
            </Link>
          </span>
          <span
            style={{ marginLeft: 10, cursor: "pointer", textAlign: "center" }}
          >
            <FontAwesomeIcon
              color="red"
              onClick={() => alertFja(row)}
              icon={faTrash}
            />
          </span>
        </div>
      ),
      width: "10%",
    },
  ];
  if (loader == false) {
    return (
      <>
        <LineWave
          height="200"
          width="200"
          color="#4fa94d"
          ariaLabel="line-wave"
          visible={true}
        />
      </>
    );
  } else {
    return (
      <Provider template={AlertMUITemplate}>
        <Row style={{ display: "block", margin: 0 }}>
          <DataTable
            columns={Kolone}
            noDataComponent={
              <p
                style={{ marginTop: 30, fontSize: "25px", textAlign: "center" }}
              >
                Ne postoje podaci za prikaz, molimo Vas{" "}
                <Link
                  style={{ color: "blue" }}
                  to="/administrator/clanci/dodaj-projekat"
                >
                  unesite novi projekat
                </Link>
                .
              </p>
            }
            onRowClicked={klikRed}
            selectableRows
            pagination={true}
            paginationComponentOptions={paginacija}
            data={podaci}
          />
        </Row>
      </Provider>
    );
  }
}
