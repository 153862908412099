import React from "react";
import Logo from "../img/mediss.png";
import { json, Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { odjava } from "../autentikacija-redux/korisnik/korisnik_slice.js";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import {
  faPhone,
  faEnvelope,
  faLocationDot,
  faFacebook,
} from "@fortawesome/free-solid-svg-icons";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export default function Navbar() {
  const korisnik = useSelector((state) => state.korisnik.value);
  const dispatch = useDispatch();
  const navigacija = useNavigate();
  console.log(korisnik);
  const odjaviti = async () => {
    const odgovor = await axios.post(
      "https://white-homes.me/odjava",
      {},
      { withCredentials: true }
    );
    console.log(odgovor);
    dispatch(odjava());
    localStorage.removeItem("korisnik");
    navigacija("/prijava");
  };
  return (
    <>
      <div className="container-fluid contic">
        <div
          className="kontakt"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div style={{ marginRight: 25 }}>
            <a href="+382/68/036-037">
              <FontAwesomeIcon
                className="logc"
                icon={faPhone}
                style={{ marginRight: 5 }}
              ></FontAwesomeIcon>
              <span className="spanicc">+382/68/036-037</span>
            </a>
          </div>

          <div style={{ marginRight: 25 }}>
            <a href="mailto:email@example.com">
              <FontAwesomeIcon
                className="logc"
                style={{ marginRight: 5 }}
                icon={faEnvelope}
              ></FontAwesomeIcon>
              <span className="spanicc">office@meddis-group.me</span>
            </a>
          </div>
          <div style={{ marginRight: 25 }}>
            <FontAwesomeIcon
              style={{ marginRight: 5 }}
              className="logc"
              icon={faLocationDot}
            ></FontAwesomeIcon>
            <span className="spanicc">Bulevar Revolucije C-7, Bar</span>
          </div>
        </div>
        <div className="drustvene">
          <div className="logc logcc">
            <FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon>
          </div>
          <div className="logc logcc">
            <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
          </div>
          <div className="logc logcc">
            <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
          </div>
        </div>
      </div>
      <div className="navbar">
        <div className="cont">
          <div className="logoo">
            <img src={Logo} />
          </div>
        </div>
      </div>
    </>
  );
}
