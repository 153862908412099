import React, { useEffect, useState } from "react";
import axios from "axios";
import { ucitajClanke } from "../../clanci-redux/sviClanci";
import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

export const Blog = () => {
  const clanciRedux = useSelector((state) => state.clanci);

  useEffect(() => {
    console.log(clanciRedux);
  }, []);

  return (
    <section class="news-alani-sayfa">
      <div class="bosluk9"></div>
      <div class="tablohizmetler">
        {clanciRedux.niz.length != 0
          ? clanciRedux.niz.map((clanak) => (
              <div class="tablo--1-ve-3" data-tilt>
                <div
                  class="post-kutu"
                  onclick="location.href='how-to-decrease-ssi-costs-of-employees.html';"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={clanak.odabrana_slika}
                    alt="Haber 3"
                    class="haber-gorsel"
                  />
                  <div class="datesection">
                    <span class="date">{clanak.datum}</span>&nbsp;
                    <span class="tt">-</span>&nbsp;
                    <span class="category">Izgradnja</span>
                  </div>
                  <h3 class="baslik-3 h-yazi-margin-kucuk">{clanak.naziv}</h3>
                  <p
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      webkitBoxOrient: "vertical",
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                    }}
                    class="post-kutu--yazi"
                  >
                    {parse(clanak.tekst)}
                  </p>
                  <div class="h-yazi-ortalama h-yazi-margin-50">
                    <Link
                      href="how-to-decrease-ssi-costs-of-employees.html"
                      class="custom-button"
                      to={`${clanak.naziv.split(" ").join("-")}`}
                    >
                      Procitaj vise
                    </Link>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </section>
  );
};
