import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import axios from "axios";
import RichTextEditor from "react-rte";
import DodajSliku from "../../components/DodajSliku";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { postaviUnosss } from "../../projekat-redux/dodajProjekat";

function DodajProjekat() {
  const [unos, postaviUnos] = useState({
    naziv: "",
    projektovanje: false,
    izgradnja: false,
    opis: "",
    slika: "",
    slikaurl: "",
    viseslika: [],
    slikeurl: [],
  });

  const [vrijednost, editorVrijednost] = useState(
    RichTextEditor.createEmptyValue()
  );
  let onChange = (value) => {
    editorVrijednost(value);
    postaviUnos((prev) => ({ ...prev, opis: vrijednost.toString("html") }));
    postaviUnosss((prev) => ({ ...prev, opis: vrijednost.toString("html") }));
  };

  let navigacija = useNavigate();
  const handleChange = (e) => {
    if (e.target.name != "izgradnja" && e.target.name != "projektovanje") {
      postaviUnos((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    } else {
      postaviUnos((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
    }
  };
  const uploadSliku = () => {
    let slika = document.getElementById("slika");
    slika.style.width = 100;
    slika.style.height = 100;
    const podaci = new FormData(); //kreiraj jebeni
    podaci.append("slika", unos.slika); //dodaj na objekat
    axios({
      method: "post",
      url: "/upload-sliku",
      data: podaci, //salji slike serveru
    })
      .then((odgovor) => {
        const { data } = odgovor; //vrati url
        console.log(data);
        postaviUnos((prev) => ({ ...prev, slikaurl: data.url }));
      })
      .then(() => console.log(unos))
      .catch((greska) => {
        console.log(greska);
      });
  };
  const uploadSlike = () => {
    const podacii = new FormData();
    for (let i = 0; i < unos.viseslika.length; i++) {
      podacii.append("slike", unos.viseslika[i]);
    } //add image to form object
    console.log(podacii.getAll("slike"));
    axios({
      method: "post",
      url: "/https://white-homes.me/upload-slike",
      data: podacii, //send image to server
    })
      .then((odgovor) => {
        const { data } = odgovor;
        postaviUnos((prev) => ({ ...prev, slikeurl: [...data.data] }));
      })
      .catch((greska) => {
        console.log(greska);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const odgovor = await axios.post("/sacuvaj-projekat", { unos });
      console.log(odgovor);

      navigacija("https://white-homes.me/administrator/projekti");
    } catch (greska) {
      console.log(greska);
    }
  };

  useEffect(() => {
    if (unos.slika != "") {
      var slidza = document.getElementById("slika");
      slidza.src = URL.createObjectURL(unos.slika);
      uploadSliku();
    }
  }, [unos.slika]);

  useEffect(() => {
    if (unos.slikaurl != "") {
      console.log(unos);
    }
  }, [unos.slikaurl]);

  useEffect(() => {
    if (unos.viseslika.length != 0) {
      uploadSlike();
    }
  }, [unos.viseslika]);

  useEffect(() => {
    if (unos.slikeurl.length != 0) {
      console.log(unos);
    }
  }, [unos.slikeurl]);

  const dodajSliku = (e) => {
    postaviUnos((prev) => ({ ...prev, slika: e.target.files[0] }));
    console.log(e.target.value);
  };
  const dodajSlike = (e) => {
    postaviUnos((prev) => ({ ...prev, viseslika: [...e.target.files] }));
  };

  return (
    <div className="auth">
      <head>
        <meta charset="utf-8" />
      </head>
      <h1 style={{ fontSize: 30, paddingTop: 20 }}>Dodaj novi projekat</h1>
      <form className="formica">
        <input
          name="naziv"
          required
          type="text"
          onChange={handleChange}
          placeholder="Naziv projekta"
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="demo">
            <label className="toggle" for="uniqueID">
              <input
                onChange={handleChange}
                type="checkbox"
                name="projektovanje"
                className="toggle__input"
                id="uniqueID"
              />
              <span className="toggle-track">
                <span className="toggle-indicator">
                  <span className="checkMark">
                    <svg
                      viewBox="0 0 24 24"
                      id="ghq-svg-check"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
                    </svg>
                  </span>
                </span>
              </span>
              Projektovanje
            </label>

            <label className="toggle">
              <input
                onChange={handleChange}
                name="izgradnja"
                type="checkbox"
                className="toggle__input"
                id="uniqueID"
              />
              <span className="toggle-track">
                <span className="toggle-indicator">
                  <span className="checkMark">
                    <svg
                      viewBox="0 0 24 24"
                      id="ghq-svg-check"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
                    </svg>
                  </span>
                </span>
              </span>
              Izgradnja
            </label>
          </div>
        </div>
        <RichTextEditor
          placeholder="Opis projekta.."
          focusOut
          value={vrijednost}
          onChange={onChange}
          name="opis"
        />
        <DodajSliku
          id="dodajSliku"
          tekst="Dodaj odabranu fotografiju"
          promjena={(e) => {
            dodajSliku(e);
          }}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          {unos.slika ? <img className="thumbnail" id="slika" /> : null}
          {unos.slika ? (
            <div className="wrapper">
              {" "}
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                {" "}
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />{" "}
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
          ) : null}{" "}
        </div>

        <DodajSliku
          id="dodajSlike"
          tekst="Dodaj slike za galeriju"
          multiple
          promjena={(e) => {
            dodajSlike(e);
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexBasis: "100%",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          {unos.viseslika.length != 0
            ? unos.viseslika.map((slika, index) => {
                let src = URL.createObjectURL(slika);
                return <img key={index} className="thumbnail" src={src} />;
              })
            : null}{" "}
          {unos.viseslika.length != 0 ? (
            <div className="wrapper">
              {" "}
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                {" "}
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />{" "}
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
          ) : null}{" "}
        </div>

        <button onClick={handleSubmit}>Sačuvaj</button>
      </form>
    </div>
  );
}

export default DodajProjekat;
