import React, { Component } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Logo from "../img/medis.png";
import Izgradnja from "../img/izgradnja.jpg";
import Projektovanje from "../img/projektovanje.webp";
import Nekretnine from "../img/nekretnine.jpg";
import izgradnja from "../img/izgradnja.svg";
import projektovanje from "../img/projektovanje.svg";
import { Audio } from "react-loader-spinner";

import nekretnine from "../img/nekretnine.svg";

export class Naslovna extends Component {
  constructor(props) {
    super(props);
    this.state = { ucitano: false };
  }
  xxx() {
    console.log(window.location.host);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ ucitano: true });
    }, 250);
  }

  render() {
    this.xxx();
    if (this.state.ucitano == true) {
      return (
        <Container fluid className="pozadina">
          <h1
            style={{
              textAlign: "center",
              color: "var(--druga)",
              fontSize: "1.5rem",
              fontFamily: "Montserrat",
              fontWeight: 800,
              textTransform: "uppercase",
            }}
          >
            Sve na jednom mjestu, od ideje do realizacije!
          </h1>
          <Container>
            <Row className="red">
              <Col className="kartica" lg={4} xs={12}>
                <a
                  onClick={() => {
                    window.location.host = `izgradnja.${window.location.hostname}`;
                  }}
                  className="link"
                >
                  <a className="card karttica">
                    <img className="slicica" src={izgradnja} />

                    <div
                      className="card__background"
                      style={{ backgroundImage: `url(${Izgradnja})` }}
                    ></div>
                    <div className="card__content">
                      <p className="card__category">meddis-group</p>
                      <h3 className="card__heading">Izgradnja</h3>
                    </div>
                  </a>
                </a>
              </Col>
              <Col className="kartica" lg={4} xs={12}>
                <a href="projektovanje">
                  <a className="card karttica">
                    <img className="slicica" src={projektovanje} />

                    <div
                      className="card__background"
                      style={{ backgroundImage: `url(${Projektovanje})` }}
                    ></div>
                    <div className="card__content">
                      <p className="card__category">meddis-group</p>
                      <h3 className="card__heading">Projektovanje</h3>
                    </div>
                  </a>
                </a>
              </Col>
              <Col className="kartica" lg={4} xs={12}>
                <Link className="link">
                  <a
                    onClick={() => {
                      window.location.host = `nekretnine.${window.location.hostname}`;
                    }}
                    className="card karttica"
                  >
                    <img className="slicica" src={nekretnine} />

                    <div
                      className="card__background"
                      style={{ backgroundImage: `url(${Nekretnine})` }}
                    ></div>
                    <div className="card__content">
                      <p className="card__category">meddis-group</p>
                      <h3 className="card__heading">Nekretnine</h3>
                    </div>
                  </a>
                </Link>
              </Col>
            </Row>
            <h4
              style={{
                textAlign: "center",
                color: "var(--druga)",
                paddingBottom: 50,
                marginBottom: 0,
              }}
            >
              30 godina tradicije
            </h4>
          </Container>
        </Container>
      );
    } else {
      return (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          wrapperStyle
          wrapperclassName
        />
      );
    }
  }
}
export default Naslovna;
