import React from "react";
import naslovnaSlika from "../../images/vila.jpg";
import divider from "../../images/divider.jpg";
import about from "../../images/industen-about.png";
import mehanizam from "../../images/technology.png";
import bager from "../../img/bager.svg";
import armatura from "../../img/armatura.svg";
import { Link } from "react-router-dom";
import krov from "../../img/krov.svg";
import fasada from "../../img/fasada.svg";
import { Audio } from "react-loader-spinner";
import glazura from "../../img/glazura.svg";
import radnik from "../../img/radnik.svg";
import sat from "../../img/sat.svg";
import odgovornost from "../../img/odgovornost.svg";
import kvalitet from "../../img/kvalitet.svg";
import Parser from "html-react-parser";
import { faTrowelBricks } from "@fortawesome/free-solid-svg-icons";
import { faHelmetSafety as kaciga } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { faCogs } from "@fortawesome/free-solid-svg-icons";

import {} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function Naslovna() {
  const [podaci, postaviPodatke] = useState([]);
  const [loader, postaviLoader] = useState(false);
  const projektiRedux = useSelector((state) => state.projekti);

  useEffect(() => {
    postaviLoader(true);
  }, []);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  if (loader == false) {
    return (
      <Audio
        height="80"
        width="80"
        radius="9"
        color="green"
        ariaLabel="loading"
        wrapperStyle
        wrapperclassName
      />
    );
  } else {
    return (
      <>
        <header className="slider">
          <div className="main-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img className="slide-image wow fadeInUp" src={naslovnaSlika} />
                <div className="container">
                  <h1>
                    Od ideje do realizacije <br />
                    <strong>sve na jednom mjestu!</strong>
                  </h1>
                  <p>
                    Stambeno-poslovne jedinice izgrađene sa visokim standardom
                    kvaliteta i pristupačnim cijenama.
                  </p>
                  <a href="#">Saznaj više &rarr;</a>
                </div>
              </div>
            </div>
          </div>

          <img src="img/slider-alt.png" className="slideraltbg" alt="" />
        </header>

        <div className="bosluk8"></div>
        <section className="hakkimizda-bolumu-anasayfa">
          <div className="h-yazi-ozel h-yazi-margin-ozel"></div>
          <div className="tablo">
            <div className="tablo--1-ve-2 ">
              <div className="galeri1" data-wow-delay="0.5s">
                <img className="imagerotate" src={mehanizam} alt="" />
              </div>
              <div
                className="galeri "
                data-wow-delay="100ms"
                data-wow-duration="1500ms"
              >
                <img
                  src={about}
                  alt="Industen About"
                  className="galeri__gorsel galeri__gorsel--3 zimage"
                />
              </div>
              <div className="countt wow fadeInUp" data-wow-delay="1s">
                <div className="counter-box ">
                  <span className="odometer" data-count="24" data-status="yes">
                    24
                  </span>
                  <h6>Godine iskustva</h6>
                </div>
              </div>
              <div className="countb wow fadeInLeft" data-wow-delay="1s">
                <div className="counter-box">
                  <span className="odometer" data-count="240" data-status="yes">
                    45
                  </span>
                  <h6>Ljudi u timu</h6>
                </div>
              </div>
            </div>
            <div className="tablo--1-ve-3 wow fadeInUp" data-wow-delay="0.5s">
              <h2 className="h2-baslik-anasayfa-ozel" data-wow-delay="0.5s">
                {" "}
                NAŠA POLITIKA POSLOVANJA{" "}
              </h2>
              <div className="bosluk333"></div>
              <p className="paragraf">
                Ključno obilježje MEDDiS GROUP je realizacija po ugovoru “ključ
                u ruke”, to jest koncepta projektovanja i izgradnje gdje
                investitor angažuje kompaniju od samog početka do završetka
                projekta i njegove potpune funkcionalnosti.
              </p>
              <div className="bosluk333"></div>
              <img
                className="divider"
                width="120"
                height="15"
                title="divider"
                alt="divider"
                src={divider}
              />
              <div className="bosluk333"></div>
              <div className="row">
                <div className="col-sm-2" data-wow-delay="0.5s">
                  <div className="iconleft">
                    <i>
                      <FontAwesomeIcon icon={faMoneyCheckDollar} />
                    </i>
                  </div>
                </div>
                <div className="col-sm-10 " data-wow-delay="0.6s">
                  <h3 className="baslik-3s h-yazi-margin-kucuk1">
                    Sigurnost ulaganja
                  </h3>
                  <br />
                  <p className="paragraf-info">
                    Poštovanje obaveza prema svakom klijentu. Garancija za
                    ispunjavanje svih svojih obaveza.
                  </p>
                  <br />
                </div>
              </div>
              <div className="bosluk1"></div>
              <div className="row">
                <div className="col-sm-2 wow fadeInUp" data-wow-delay="0.7s">
                  <div className="iconleft">
                    <i className="flaticon-elastic">
                      <img src={sat} />
                    </i>
                  </div>
                </div>
                <div className="col-sm-10 wow fadeInUp" data-wow-delay="0.8s">
                  <h3 className="baslik-3s h-yazi-margin-kucuk1">
                    Poštovanje rokova
                  </h3>
                  <br />
                  <p className="paragraf-info">
                    Poštovanje rokova od početka gradnje do roka primopredaje
                    nekretnine, kao i poštovanje rokova prema partnerima.
                  </p>
                  <br />
                </div>
              </div>
              <div className="bosluk1"></div>
              <div className="row">
                <div className="col-sm-2 wow fadeInUp" data-wow-delay="0.9s">
                  <div className="iconleft">
                    <i>
                      <img src={kvalitet} />
                    </i>
                  </div>
                </div>
                <div className="col-sm-10 wow fadeInUp" data-wow-delay="1s">
                  <h3 className="baslik-3s h-yazi-margin-kucuk1">
                    Kvalitet proizvoda
                  </h3>
                  <br />
                  <p className="paragraf-info">
                    Stambeno-poslovne jedinice izgrađene sa visokim standardom
                    kvaliteta i pristupačnim cijenama.
                  </p>
                  <br />
                </div>
              </div>
              <div className="bosluk1"></div>
              <div className="row">
                <div className="col-sm-2 wow fadeInUp" data-wow-delay="0.9s">
                  <div className="iconleft">
                    <i>
                      <img src={odgovornost} />
                    </i>
                  </div>
                </div>
                <div className="col-sm-10 wow fadeInUp" data-wow-delay="1s">
                  <h3 className="baslik-3s h-yazi-margin-kucuk1">
                    Odgovornost
                  </h3>
                  <br />
                  <p className="paragraf-info">
                    Poštovanje ekoloških standarda i principa održivig razvoja i
                    organizovanje i podržavanje društveno odgovornih projekata.
                  </p>
                  <br />
                </div>
              </div>
              <div className="bosluk333"></div>
              <a href="about-us.html" className="custom-button">
                Saznajte više o nama
              </a>
              <div className="bosluk3rh"></div>
            </div>
          </div>
        </section>

        {/* Usluge */}

        <section className="service " data-wow-delay="0.2s">
          <div className="container">
            <div className="row">
              <div className="col-md-12 " data-wow-delay="0.3s">
                <h2 className="h3-baslik-anasayfa-beyaz">
                  OD ODEJE DO{" "}
                  <span style={{ color: "var(--druga)" }}>KLJUČA</span>
                  <br />
                  SVE NA JEDNOM MJESTU!
                </h2>
              </div>
              <div className="bosluk3"></div>
            </div>
          </div>
          {/*<!--Service 1-->*/}
          <div className="bosluk3"></div>
          <div className="tabloozellik">
            <div className="tablo--1-ve-4">
              <div
                className="paketler"
                data-wow-delay="0.4s"
                onClick="location.href='steel-industry.html';"
                style={{ cursor: "pointer" }}
              >
                <div className="hizmet-kutu">
                  <div className="kutu-duzen">
                    <div className="icon-box">
                      <span className="border-layer"></span>
                      <i>
                        <img src={bager} style={{ width: 60 }} />
                      </i>
                    </div>
                    <h2 className="hbt">
                      <a href="steel-industry.html"> Zemljani radovi </a>
                    </h2>
                    <p>
                      Sve vrste zemljanih radova. Izgradnja parkinga i uređenje
                      dvorišta.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*<!--Service 2-->*/}
            <div className="tablo--1-ve-4">
              <div
                className="paketler"
                data-wow-delay="0.5s"
                onClick="location.href='energy-industry.html';"
                style={{ cursor: "pointer" }}
              >
                <div className="hizmet-kutu">
                  <div className="kutu-duzen">
                    <div className="icon-box">
                      <span className="border-layer"></span>
                      <i>
                        <img src={armatura} style={{ width: 60 }} />
                      </i>
                    </div>
                    <h2 className="hbt">
                      <a href="energy-industry.html"> Armirački radovi </a>
                    </h2>
                    <p>
                      Nabavka, transport obrada i montaža armaturno građevinskog
                      željeza.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*<!--Service 3-->*/}
            <div className="tablo--1-ve-4">
              <div
                className="paketler wow fadeInRight"
                data-wow-delay="0.6s"
                onClick="location.href='logistics-industry.html';"
                style={{ cursor: "pointer" }}
              >
                <div className="hizmet-kutu">
                  <div className="kutu-duzen">
                    <div className="icon-box">
                      <span className="border-layer"></span>
                      <i>
                        <FontAwesomeIcon
                          style={{ width: 60 }}
                          icon={faTrowelBricks}
                        />
                      </i>
                    </div>
                    <h2 className="hbt">
                      <a href="logistics-industry.html"> Gradnja </a>
                    </h2>
                    <p>
                      Zidanje i rekonstrukcija objekata opekom, kamenom,
                      armiranim betonom...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*<!--Service 4-->*/}
            <div className="tablo--1-ve-4">
              <div
                className="paketler wow fadeInRight"
                data-wow-delay="0.7s"
                onClick="location.href='forest-products.html';"
                style={{ cursor: "pointer" }}
              >
                <div className="hizmet-kutu">
                  <div className="kutu-duzen">
                    <div className="icon-box">
                      <span className="border-layer"></span>
                      <i className="flaticon-truck">
                        <img src={krov} style={{ width: 60 }} />
                      </i>
                    </div>
                    <h2 className="hbt">
                      <a href="forest-products.html">
                        {" "}
                        Krovopokrivački radovi{" "}
                      </a>
                    </h2>
                    <p>Gradnja krova, nadstrešnice i dr..</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bozel"></div>
          {/*<!--Service 5-->*/}
          <div className="tabloozellik">
            <div className="tablo--1-ve-4">
              <div
                className="paketler wow fadeInLeft"
                data-wow-delay="0.4s"
                onClick="location.href='fertilizer-industry.html';"
                style={{ cursor: "pointer" }}
              >
                <div className="hizmet-kutu">
                  <div className="kutu-duzen">
                    <div className="icon-box">
                      <span className="border-layer"></span>
                      <i className="flaticon-water-drop">
                        <img src={fasada} style={{ width: 60 }} />
                      </i>
                    </div>
                    <h2 className="hbt">
                      <a href="fertilizer-industry.html"> Fasade </a>
                    </h2>
                    <p>
                      Fasaderski radovi, postavljanje termo i stiropor fasade,
                      kamene fasade..
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*<!--Service 6-->*/}
            <div className="tablo--1-ve-4">
              <div
                className="paketler wow fadeInLeft"
                data-wow-delay="0.5s"
                onClick="location.href='port-management.html';"
                style={{ cursor: "pointer" }}
              >
                <div className="hizmet-kutu">
                  <div className="kutu-duzen">
                    <div className="icon-box">
                      <span className="border-layer"></span>
                      <i className="flaticon-cruise">
                        <img src={glazura} style={{ width: 60 }} />
                      </i>
                    </div>
                    <h2 className="hbt">
                      <a href="port-management.html"> Glazure </a>
                    </h2>
                    <p>
                      Glazure, hidroizolacijske glazure, termoizolacijske
                      glazure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*<!--Service 7-->*/}
            <div className="tablo--1-ve-4">
              <div
                className="paketler wow fadeInRight"
                data-wow-delay="0.6s"
                onClick="location.href='chemical-industry.html';"
                style={{ cursor: "pointer" }}
              >
                <div className="hizmet-kutu">
                  <div className="kutu-duzen">
                    <div className="icon-box">
                      <span className="border-layer"></span>
                      <i className="flaticon-dangerous">
                        <FontAwesomeIcon icon={kaciga} style={{ width: 60 }} />
                      </i>
                    </div>
                    <h2 className="hbt">
                      <a href="chemical-industry.html"> Završni gr. radovi</a>
                    </h2>
                    <p>
                      Postavljanje parketa, laminata, pločica, pvc podova,
                      prozora, vrata, keramike, kamena...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*<!--Service 8-->*/}
            <div className="tablo--1-ve-4">
              <div
                className="paketler wow fadeInRight"
                data-wow-delay="0.7s"
                onClick="location.href='insurance.html';"
                style={{ cursor: "pointer" }}
              >
                <div className="hizmet-kutu">
                  <div className="kutu-duzen">
                    <div className="icon-box">
                      <span className="border-layer"></span>
                      <i className="flaticon-insurance">
                        <img src={radnik} style={{ width: 60 }} />
                      </i>
                    </div>
                    <h2 className="hbt">
                      <a href="insurance.html"> Ostali gr. radovi </a>
                    </h2>
                    <p>
                      Malterisanje, molerski radovi, gradnja zidova i pregradnih
                      zidova, priprema za elektroinstalacije...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Projekti */}

        <section
          className="blog-alani-sayfa wow fadeInUp"
          data-wow-delay="0.2s"
        >
          <div className="container">
            <div className="row ">
              <div className="col-12" data-wow-delay="0.3s">
                <div className="h-yazi-ortalama h-yazi-margin-orta-3">
                  <div className="icon">
                    <FontAwesomeIcon
                      icon={faCogs}
                      className="flaticon-recommended"
                    ></FontAwesomeIcon>
                  </div>
                  <h2 className="h2-baslik-hizmetler-yorum">
                    {" "}
                    Poslednji projekti{" "}
                  </h2>
                </div>
                <p className="h2-baslik-hizmetler-yorum__yorum">
                  OD ODEJE DO KLJUČA – SVE NA JEDNOM MJESTU!
                </p>
                <div className="bosluk3a"></div>
              </div>
            </div>
          </div>
          <div className="container-fluid fluida">
            <Carousel
              responsive={responsive}
              autoPlaySpeed={1000}
              showDots={true}
            >
              {projektiRedux.niz.map((podatak) => (
                <div
                  className="post-kutu"
                  onClick="location.href='colors-in-interior-decoration.html';"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={podatak.odabrana_slika}
                    alt="Haber 1"
                    className="haber-gorsel"
                  />

                  <h3 className="baslik-3 h-yazi-margin-kucuk">
                    {podatak.naziv}
                  </h3>
                  <p className="post-kutu--yazi">{Parser(podatak.opis)}</p>
                  <div className="h-yazi-ortalama h-yazi-margin-4">
                    <Link
                      className="custom-button"
                      to={`/projekti/${podatak.naziv.split(" ").join("-")}`}
                    >
                      Pogledaj više
                    </Link>
                  </div>
                </div>
              ))}
            </Carousel>

            <div></div>
          </div>
        </section>
        {/* Izgradnja */}

        <section className="service" id="Izgradnja" data-wow-delay="0.2s">
          <div className="container">
            <div className="row">
              <div className="col-md-12 " data-wow-delay="0.3s">
                <h2 className="h3-baslik-anasayfa-beyaz">Izgradnja</h2>
              </div>
            </div>
          </div>
          {/*<!--Service 1-->*/}
          <div className="bosluk3"></div>
          <div className="tabloozellik">
            <div className="tablo--1-ve-3">
              <div
                className="paketler"
                data-wow-delay="0.4s"
                onClick="location.href='steel-industry.html';"
                style={{ cursor: "pointer" }}
              >
                <div className="hizmet-kutu">
                  <div className="kutu-duzen">
                    <div className="icon-box">
                      <span className="border-layer"></span>
                      <i>
                        <img src={bager} style={{ width: 60 }} />
                      </i>
                    </div>
                    <h2 className="hbt">
                      <a href="steel-industry.html"> Visokogradnja </a>
                    </h2>
                    <p>
                      Gradnja objekata Izvođenje svih građevinsko-zanatskih
                      radova, vode i kanalizacije, elektro instalacije na
                      porodičnim kućama, hotelima, restoranima, industrijskim i
                      poslovnim objektima, benzinskim pumpama, sakralnim
                      objektima, objektima prosvjete i kulture (škole, vrtići,
                      domovi…).
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*<!--Service 2-->*/}
            <div className="tablo--1-ve-3">
              <div
                className="paketler"
                data-wow-delay="0.5s"
                onClick="location.href='energy-industry.html';"
                style={{ cursor: "pointer" }}
              >
                <div className="hizmet-kutu">
                  <div className="kutu-duzen">
                    <div className="icon-box">
                      <span className="border-layer"></span>
                      <i>
                        <img src={armatura} style={{ width: 60 }} />
                      </i>
                    </div>
                    <h2 className="hbt">
                      <a href="energy-industry.html"> Rekonstrukcija </a>
                    </h2>
                    <p>
                      Obnavljanje i uređenje stambenih objekata, objekata pod
                      kulturnom zaštitom, svih poslovnih, sportskih, društvenih
                      i industrijskih objekata koji pripadaju ovoj vrsti
                      djelatnosti.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*<!--Service 3-->*/}
            <div className="tablo--1-ve-3">
              <div
                className="paketler wow fadeInRight"
                data-wow-delay="0.6s"
                onClick="location.href='logistics-industry.html';"
                style={{ cursor: "pointer" }}
              >
                <div className="hizmet-kutu">
                  <div className="kutu-duzen">
                    <div className="icon-box">
                      <span className="border-layer"></span>
                      <i>
                        <FontAwesomeIcon
                          style={{ width: 60 }}
                          icon={faTrowelBricks}
                        />
                      </i>
                    </div>
                    <h2 className="hbt">
                      <a href="logistics-industry.html"> Stanogradnja </a>
                    </h2>
                    <p>
                      Izgradnja stambenih i stambeno – poslovnih objekata za
                      poznate Investitore kao i u vlastitoj režiji te prodaja
                      istih fizičkim i pravnim licima.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* partneri */}
        <section class="markalar">
          <h6>.</h6>
          <div class="h-yazi-ortalama h-yazi-margin-kucuk-21">
            <img
              src={require("../../img/okov.png")}
              alt="Marka 1"
              class="marka"
            />
            <img
              src={require("../../img/kips.jpg")}
              alt="Marka 2"
              class="marka"
            />
            <img
              src={require("../../img/luksal.png")}
              alt="Marka 3"
              class="marka"
            />
            <img
              src={require("../../img/drvomont.png")}
              alt="Marka 4"
              className="marka"
            />
            <img
              src={require("../../img/eplus.jpg")}
              alt="Marka 5"
              className="marka"
            />
          </div>
        </section>
        {/* Footer */}
      </>
    );
  }
}
