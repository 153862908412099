import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import axios from "axios";
import RichTextEditor from "react-rte";
import DodajSliku from "../../components/DodajSliku";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { postaviUnosss } from "../../projekat-redux/dodajClanak";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
function IzmijeniClanak() {
  const clanci = useSelector((state) => state.clanak);
  const dispatch = useDispatch();

  const [unos, postaviUnos] = useState({
    naziv: "",
    projektovanje: false,
    izgradnja: false,
    tekst: "",
    slika: "",
    slikaurl: "",
  });

  const [vrijednost, editorVrijednost] = useState(
    RichTextEditor.createValueFromString(clanci.unos.tekst, "html")
  );
  let onChange = (value) => {
    editorVrijednost(value);
    postaviUnos((prev) => ({ ...prev, tekst: vrijednost.toString("html") }));
    dispatch(postaviUnosss({ tekst: vrijednost.toString("html") }));
  };

  let slikeurl = [];

  useEffect(() => {
    console.log(clanci.unos);
  }, [clanci]);

  let navigacija = useNavigate();
  const handleChange = (e) => {
    if (e.target.name != "izgradnja" && e.target.name != "projektovanje") {
      postaviUnos((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      dispatch(postaviUnosss({ [e.target.name]: e.target.value }));
    } else {
      postaviUnos((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
      dispatch(postaviUnosss({ [e.target.name]: e.target.checked }));
    }
  };
  const uploadSliku = () => {
    let slika = document.getElementById("slika");
    slika.style.width = 100;
    slika.style.height = 100;
    const podaci = new FormData(); //kreiraj jebeni
    podaci.append("slika", clanci.unos.slika); //dodaj na objekat
    axios({
      method: "post",
      url: "/upload-sliku",
      data: podaci, //salji slike serveru
    })
      .then((odgovor) => {
        const { data } = odgovor; //vrati url
        console.log(data.url);
        dispatch(postaviUnosss({ slikaurl: data.url }));
        dispatch(postaviUnosss({ odabrana_slika: data.url }));
      })
      .then(() => console.log(clanci.unos))
      .catch((greska) => {
        console.log(greska);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(clanci.unos);
      const odgovor = await axios.post("/izmijeni-clanak", { ...clanci.unos });
      console.log(odgovor);

      navigacija("/administrator/clanci");
    } catch (greska) {
      console.log(greska);
    }
  };

  useEffect(() => {
    if (clanci.unos.slika != "") {
      var slidza = document.getElementById("slika");
      slidza.src = clanci.unos.odabrana_slika;
      uploadSliku();
    }
  }, [clanci.unos.slika]);

  useEffect(() => {
    if (clanci.unos.slikaurl != "") {
      console.log(clanci.unos);
    }
  }, [clanci.unos.slikaurl]);

  const dodajSliku = (e) => {
    postaviUnos((prev) => ({ ...prev, slika: e.target.files[0] }));
    dispatch(postaviUnosss({ slika: e.target.files[0] }));
  };
  const dodajSlike = (e) => {
    dispatch(postaviUnosss({ viseslika: [] }));

    dispatch(postaviUnosss({ viseslika: [...e.target.files] }));
    dispatch(postaviUnosss({ slikeurl: [] }));
  };

  return (
    <div className="auth">
      <head>
        <meta charset="utf-8" />
      </head>
      <h1 style={{ fontSize: 30, paddingTop: 20 }}>
        Izmijeni Članak{" "}
        <span style={{ color: "red" }}>{clanci.unos.naziv}</span>
      </h1>
      <form className="formica">
        <input
          name="naziv"
          value={clanci.unos.naziv}
          required
          type="text"
          onChange={handleChange}
          placeholder="Naziv projekta"
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="demo">
            <label className="toggle" for="uniqueID">
              <input
                onChange={handleChange}
                type="checkbox"
                checked={clanci.unos.projektovanje}
                name="projektovanje"
                className="toggle__input"
                id="uniqueID"
              />
              <span className="toggle-track">
                <span className="toggle-indicator">
                  <span className="checkMark">
                    <svg
                      viewBox="0 0 24 24"
                      id="ghq-svg-check"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
                    </svg>
                  </span>
                </span>
              </span>
              Projektovanje
            </label>

            <label className="toggle">
              <input
                onChange={handleChange}
                checked={clanci.unos.izgradnja}
                name="izgradnja"
                type="checkbox"
                className="toggle__input"
                id="uniqueID"
              />
              <span className="toggle-track">
                <span className="toggle-indicator">
                  <span className="checkMark">
                    <svg
                      viewBox="0 0 24 24"
                      id="ghq-svg-check"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
                    </svg>
                  </span>
                </span>
              </span>
              Izgradnja
            </label>
          </div>
        </div>
        <RichTextEditor
          placeholder="tekst projekta.."
          focusOut
          value={vrijednost}
          onChange={onChange}
          name="tekst"
        />
        <DodajSliku
          id="dodajSliku"
          tekst="Dodaj odabranu fotografiju"
          promjena={(e) => {
            dodajSliku(e);
          }}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          {clanci.unos.odabrana_slika ? (
            <img className="thumbnail" src={clanci.unos.slikaurl} id="slika" />
          ) : null}
          {clanci.unos.odabrana_slika ? (
            <div className="wrapper">
              {" "}
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                {" "}
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />{" "}
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
          ) : null}{" "}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexBasis: "100%",
            width: "100%",
            flexWrap: "wrap",
          }}
        ></div>

        <button onClick={handleSubmit}>Sačuvaj</button>
      </form>
    </div>
  );
}

export default IzmijeniClanak;
