import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
function Registracija() {
  const [prikazi, postaviPrikazano] = useState("none");
  const [unos, postaviUnos] = useState({
    ime: "",
    email: "",
    sifra: "",
  });
  let navigacija = useNavigate();
  console.log(unos);
  const handleChange = (e) => {
    postaviUnos((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const odgovor = await axios.post("https://white-homes.me/registracija", {
        unos,
      });
      if (odgovor.data == "Korisnik sa unijetim podacima već postoji.") {
        postaviPrikazano("block");
        console.log(odgovor);
      } else {
        postaviPrikazano("none");
        navigacija("/");

        console.log(odgovor);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="auth">
      <h1>Registracija</h1>
      <form>
        <input
          name="ime"
          required
          type="text"
          onChange={handleChange}
          placeholder="ime"
        />
        <input
          name="email"
          required
          type="email"
          onChange={handleChange}
          placeholder="email"
        />
        <input
          name="sifra"
          required
          type="text"
          onChange={handleChange}
          placeholder="sifra"
        />
        <button onClick={handleSubmit}>Registruj se</button>
        <p style={{ display: prikazi }}>
          Korisnik sa postojećim podacima za autentikaciju već postoji
        </p>
        <span>
          Da li već imate nalog? <Link to="/login">Prijavi se</Link>
        </span>
      </form>
    </div>
  );
}

export default Registracija;
