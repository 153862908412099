import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Prijava from "../Prijava";
import { Outlet } from "react-router-dom";
import axios from "axios";
import { ProSidebarProvider } from "react-pro-sidebar";
import Sidebarr from "../../components/Sidebar";
export default function Admin() {
  let x = document.cookie;
  axios.post("https://white-homes.me/administracija", { x });
  const navigacija = useNavigate();
  const korisnik = useSelector((state) => state.korisnik.value);
  if (korisnik == null) {
    return <Prijava />;
  }
  return (
    <ProSidebarProvider>
      <div className="admin">
        <Sidebarr />
        <div className="main">
          <Outlet></Outlet>
        </div>
      </div>
    </ProSidebarProvider>
  );
}
