import { createSlice } from '@reduxjs/toolkit'

export const projekatSlice = createSlice({
  name: 'projekat',
  initialState: {
    unos:{
      id:"",
      naziv:"",
      slika:"",
      projektovanje:false,
      izgradnja:false,
      opis:"",
      odabrana_slika:"",
      slikaurl:"",
      viseslika:[],
      slikeurl:[],}
    ,
  },
  reducers: {
    postaviUnosss: (state,projekat) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.unos={...state.unos,...projekat.payload};
    },

  },
})

// Action creators are generated for each case reducer function
export const { postaviUnosss} = projekatSlice.actions;

export default projekatSlice.reducer;
