import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LineWave } from "react-loader-spinner";
import Parser from "html-react-parser";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import axios from "axios";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    itemWidth: 500,
    slidesToSlide: 1, // optional, default to 1.
  },
};
export default function () {
  let projektiRedux = useSelector((state) => state.projekti);
  let [projekat, postaviProjekat] = useState("");
  let [slika, prikaziSliku] = useState(false);
  let [url, postaviUrl] = useState("");

  let [galerija, postaviGaleriju] = useState(null);
  let galerijaa = async (projekat) => {
    console.log(projekat);
    return await axios.post("https://white-homes.me/galerija", {
      id: projekat.id,
    });
  };

  let x = document.location.pathname
    .split("/")
    [document.location.pathname.split("/").length - 1].split("%20")
    .join("-");

  console.log(x);
  useEffect(() => {
    if (projektiRedux.niz.length != 0) {
      console.log(projektiRedux);
      let b = projektiRedux.niz.filter(
        (p) => p.naziv.split(" ").join("-").toLowerCase() == x.toLowerCase()
      );
      console.log(b[0]);
      postaviProjekat(b[0]);
    }
  }, [projektiRedux]);
  useEffect(() => {
    galerijaa(projekat).then((rez, greska) => {
      if (rez) {
        console.log(rez);
        postaviGaleriju(rez.data);
      } else {
        console.log("greska" + greska);
      }
    });
  }, [projekat]);
  const urlSlike = (e) => {
    console.log(e.target.src);
    postaviUrl(e.target.src);
    prikaziSliku(true);
  };
  const ukloniSliku = (e) => {
    if (e.target.nodeName != "IMG") {
      prikaziSliku(false);
    }
    return;
  };

  if (projekat != "") {
    return (
      <main>
        <section className="hizmetler-detay-sayfasi-alani">
          <div
            onClick={(e) => {
              ukloniSliku(e);
            }}
            style={
              slika == true
                ? {
                    display: "block",
                    position: "fixed",
                    left: "50%",
                    top: "50%",
                    transform: `translate(-50%, -50%)`,
                    background: "rgba(3, 15, 39, 0.5)",
                    overflow: "hidden",
                    width: "100%",
                    height: "100vh",
                    zIndex: 999999999999,
                    transition: "2s",
                    opacity: 1,
                  }
                : {
                    position: "fixed",
                    left: "50%",
                    top: "50%",
                    transform: `translate(-50%, -50%)`,
                    background: "rgba(3, 15, 39, 0.5)",
                    overflow: "hidden",
                    width: "100%",
                    height: "100vh",
                    zIndex: 999999999999,
                    display: "none",
                    transition: "2s",
                    opacity: 0,
                  }
            }
          >
            <img
              style={{
                position: "absolute",
                width: "50%",
                left: "50%",
                top: "50%",
                zIndex: 999999999999999,
                transform: "translate(-50%,-50%)",
              }}
              src={url}
              className="zoom"
            />
          </div>
          <div style={{ position: "relative" }} className="container">
            <div className="row">
              {/*Proje Görsel Alanı*/}
              <div className="col-md-6">
                <div className="galeri wow fadeInLeft" data-wow-delay="0.5s">
                  <img
                    src={projekat.odabrana_slika}
                    alt="Project Detail"
                    className="galeri__gorsel galeri__gorsel--4"
                  />
                  <section
                    style={{ overflow: "hidden" }}
                    className="gallery-alani"
                  >
                    <div className="red row">
                      {galerija ? (
                        <div
                          style={{ margin: 0, padding: 0 }}
                          className="container-fluid"
                        >
                          <Carousel
                            className="xxx"
                            responsive={responsive}
                            showDots={true}
                          >
                            {galerija.map((slika, index) => (
                              <>
                                <img
                                  onClick={(e) => {
                                    urlSlike(e);
                                  }}
                                  alt={slika.slika}
                                  key={slika.id}
                                  src={slika.slika}
                                />
                              </>
                            ))}
                          </Carousel>
                        </div>
                      ) : (
                        <LineWave
                          className="xxx"
                          height="200"
                          width="200"
                          color="#4fa94d"
                          ariaLabel="line-wave"
                          visible={true}
                        />
                      )}
                    </div>
                  </section>
                </div>
              </div>{" "}
              {/*Hizmetler Detay Yazı Alanı*/}
              <div className="col-md-6">
                <div
                  style={{ marginTop: "-5rem", cursor: "pointer" }}
                  className="services-kutu1 wow fadeInRight"
                  data-wow-delay="0.6s"
                >
                  <div className="icon-project-detail">
                    <FontAwesomeIcon
                      style={{
                        color: "#173e76",
                        fontSize: "80px",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                      icon={faGear}
                    />
                  </div>
                  <div style={{ display: "block" }}>
                    <ul
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "left",
                        flexDirection: "column",
                      }}
                    >
                      <li className="opissi">
                        <strong>Godina</strong> : 2023
                      </li>
                      <li className="opissi">
                        <strong>Kompanija</strong> : Meddis group
                      </li>
                      <li className="opissi">
                        <strong>Naziv projekta</strong> : {projekat.naziv}
                      </li>
                      <li className="opissi">
                        <strong>Lokacija</strong> : Bar, Crna Gora
                      </li>
                      <li className="opissi">
                        <strong>Opis</strong> : {Parser(projekat.opis)}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  } else {
    return (
      <>
        <LineWave
          className="xxx"
          height="200"
          width="200"
          color="#4fa94d"
          ariaLabel="line-wave"
          visible={true}
        />
      </>
    );
  }
}
