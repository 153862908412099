import React, { useEffect, useState } from "react";
import slika from "../../images/page-header.jpg";
import { Link } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Breadcrumb() {
  const [path, postaviPath] = useState("");
  let ruta = decodeURI(document.location.pathname);
  const breadcrumb = () => {
    postaviPath(ruta.split("/"));
  };
  const [naslov, postaviNaslov] = useState("");
  const naslovi = () => {
    let x = ruta.split("/")[ruta.split("/").length - 1].split("%20");
    let niz = x.map((y) => y + " ");
    postaviNaslov(niz);
    return niz;
  };
  useEffect(() => {
    breadcrumb();
  }, []);
  useEffect(() => {
    naslovi();
  }, [path]);
  return (
    <header
      className="page-header wow fadeInUp"
      data-wow-delay="0.5s"
      data-background="img/page-header.jpg"
      style={{
        background: `url(${slika})`,
        visibility: "visible",
      }}
    >
      <div className="container">
        <h2 style={{ textTransform: "capitalize" }}>{naslov}</h2>
        <div className="bosluk3"></div>
        <p>
          <Link to="/">Naslovna</Link>
          {ruta.split("/").map((pathhh, index) =>
            ruta.split("/").length - 1 != index ? (
              <span>
                <Link to={"/" + pathhh} style={{ display: "inlineBlock" }}>
                  {pathhh}
                </Link>
                {ruta.split("/").length - 1 != index ? (
                  <span>
                    &nbsp;
                    <FontAwesomeIcon icon={faArrowRight} />
                    &nbsp;
                  </span>
                ) : null}
              </span>
            ) : (
              <span>{pathhh.split("%20")}</span>
            )
          )}
        </p>
      </div>
    </header>
  );
}
