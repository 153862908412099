import React from "react";
import Logo from "../../img/logo.svg";
import slajder from "../../img/slider/1.jpg";

export default function Naslovna() {
  return (
    <div>
      <html lang="zxx">
        <body>
          <div class="progress-wrap cursor-pointer">
            <svg
              class="progress-circle svg-content"
              width="100%"
              height="100%"
              viewBox="-1 -1 102 102"
            >
              <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
            </svg>
          </div>
          <nav class="navbar navbar-expand-md">
            <div class="container">
              <div class="logo-wrapper">
                <a class="logo" href="index.html">
                  {" "}
                  <img src={Logo} class="logo-img" alt="" />{" "}
                </a>
                <a class="logo" href="index.html">
                  {" "}
                  <h2>BAUEN</h2>{" "}
                </a>
              </div>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar"
                aria-controls="navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                {" "}
                <span class="navbar-toggler-icon">
                  <i class="ti-menu"></i>
                </span>{" "}
              </button>
              <div class="collapse navbar-collapse" id="navbar">
                <ul class="navbar-nav ms-auto">
                  <li class="nav-item">
                    <a class="nav-link active" href="#" data-scroll-nav="0">
                      Home
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#" data-scroll-nav="1">
                      About
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#" data-scroll-nav="2">
                      Projects
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#" data-scroll-nav="3">
                      Services
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#" data-scroll-nav="4">
                      Team
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#" data-scroll-nav="5">
                      Careers
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#" data-scroll-nav="6">
                      Blog
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#" data-scroll-nav="7">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <header id="home" class="header slider-fade" data-scroll-index="0">
            <div class="owl-carousel owl-theme">
              <div
                class="text-left item bg-img"
                data-overlay-dark="3"
                style={{ backgroundImage: `url(${slajder})` }}
              >
                <div class="v-middle caption mt-30">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-7">
                        <h1>Innovate Desing in Toronto</h1>
                        <p>
                          Architecture viverra tellus nec massa dictum the
                          euismoe.
                          <br />
                          Curabitur viverra the posuere aukue velit.
                        </p>
                        <div class="butn-light mt-30 mb-30">
                          <a
                            href="https://1.envato.market/mDnXD"
                            target="_blank"
                          >
                            <span>Buy Now</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="text-left item bg-img"
                data-overlay-dark="4"
                data-background="img/slider/2.jpg"
              >
                <div class="v-middle caption mt-30">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-7">
                        <h1>Innovate Desing in Canada</h1>
                        <p>
                          Architecture viverra tellus nec massa dictum the
                          euismoe.
                          <br />
                          Curabitur viverra the posuere aukue velit.
                        </p>
                        <div class="butn-light mt-30 mb-30">
                          <a
                            href="https://1.envato.market/mDnXD"
                            target="_blank"
                          >
                            <span>Buy Now</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div class="content-wrapper">
            <section class="content-lines-wrapper">
              <div class="content-lines-inner">
                <div class="content-lines"></div>
              </div>
            </section>
            <section
              id="about"
              class="about section-padding"
              data-scroll-index="1"
            >
              <div class="container">
                <div class="row">
                  <div
                    class="col-md-6 mb-30 animate-box"
                    data-animate-effect="fadeInUp"
                  >
                    <h2 class="section-title">
                      About <span>Bauen</span>
                    </h2>
                    <p>
                      Architecture viverra tristique justo duis vitae diam neque
                      nivamus aestan ateuene artines aringianu atelit finibus
                      viverra nec lacus. Nedana theme erodino setlie suscipe no
                      curabit tristique.
                    </p>
                    <p>
                      Design inilla duiman at elit finibus viverra nec a lacus
                      themo the drudea seneoice misuscipit non sagie the fermen.
                    </p>
                    <p>
                      Planner inilla duiman at elit finibus viverra nec a lacus
                      themo the drudea seneoice misuscipit non sagie the fermen.
                      Viverra tristique jusio the ivite dianne onen nivami
                      acsestion augue artine.
                    </p>
                  </div>
                  <div
                    class="col-md-6 animate-box"
                    data-animate-effect="fadeInUp"
                  >
                    <div class="about-img">
                      <div class="img">
                        {" "}
                        <img
                          src="img/about.jpg"
                          class="img-fluid"
                          alt=""
                        />{" "}
                      </div>
                      <div class="about-img-2 about-buro">Canada Office</div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="projects"
              class="projects section-padding"
              data-scroll-index="2"
            >
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <h2 class="section-title">
                      Our <span>Projects</span>
                    </h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="owl-carousel owl-theme">
                      <div class="item">
                        <div class="position-re o-hidden">
                          {" "}
                          <img src="img/projects/1.jpg" alt="" />{" "}
                        </div>
                        <div class="con">
                          <h6>
                            <a href="cotton-house.html">Interior</a>
                          </h6>
                          <h5>
                            <a href="cotton-house.html">Cotton House</a>
                          </h5>
                          <div class="line"></div>{" "}
                          <a href="cotton-house.html">
                            <i class="ti-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="position-re o-hidden">
                          {" "}
                          <img src="img/projects/2.jpg" alt="" />{" "}
                        </div>
                        <div class="con">
                          <h6>
                            <a href="armada-center.html">Exterior</a>
                          </h6>
                          <h5>
                            <a href="armada-center.html">Armada Center</a>
                          </h5>
                          <div class="line"></div>{" "}
                          <a href="armada-center.html">
                            <i class="ti-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="position-re o-hidden">
                          {" "}
                          <img src="img/projects/3.jpg" alt="" />{" "}
                        </div>
                        <div class="con">
                          <h6>
                            <a href="stonya-villa.html">Urban</a>
                          </h6>
                          <h5>
                            <a href="stonya-villa.html">Stonya Villa</a>
                          </h5>
                          <div class="line"></div>{" "}
                          <a href="stonya-villa.html">
                            <i class="ti-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                      <div class="item">
                        <div class="position-re o-hidden">
                          {" "}
                          <img src="img/projects/4.jpg" alt="" />{" "}
                        </div>
                        <div class="con">
                          <h6>
                            <a href="prime-hotel.html">Interior</a>
                          </h6>
                          <h5>
                            <a href="prime-hotel.html">Prime Hotel</a>
                          </h5>
                          <div class="line"></div>{" "}
                          <a href="prime-hotel.html">
                            <i class="ti-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="services"
              class="services section-padding"
              data-scroll-index="3"
            >
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <h2 class="section-title">
                      Our <span>Services</span>
                    </h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="item">
                      <a href="architecture.html">
                        {" "}
                        <img src="img/icons/icon-1.png" alt="" />
                        <h5>Architecture</h5>
                        <div class="line"></div>
                        <p>
                          Architecture bibendum eros eget lacus the vulputate
                          sit amet vehicuta nibhen ulicera in the vitae miss.
                        </p>
                        <div class="numb">01</div>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="item">
                      <a href="interior-design.html">
                        {" "}
                        <img src="img/icons/icon-2.png" alt="" />
                        <h5>Interior Design</h5>
                        <div class="line"></div>
                        <p>
                          Architecture bibendum eros eget lacus the vulputate
                          sit amet vehicuta nibhen ulicera in the vitae miss.
                        </p>
                        <div class="numb">02</div>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="item">
                      <a href="urban-design.html">
                        {" "}
                        <img src="img/icons/icon-3.png" alt="" />
                        <h5>Urban Design</h5>
                        <div class="line"></div>
                        <p>
                          Architecture bibendum eros eget lacus the vulputate
                          sit amet vehicuta nibhen ulicera in the vitae miss.
                        </p>
                        <div class="numb">03</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="team"
              class="team section-padding"
              data-scroll-index="4"
            >
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <h2 class="section-title">
                      Our <span>Team</span>
                    </h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 owl-carousel owl-theme">
                    <div class="item">
                      <div class="img">
                        <a href="team-details.html">
                          <img src="img/team/1.jpg" alt="" />
                        </a>
                      </div>
                      <div class="info">
                        <h6>Jason Brown</h6>
                        <p>Architect</p>
                        <div class="social valign">
                          <div class="full-width">
                            <p>dipl. Arch ETH/SIA</p>{" "}
                            <a href="#">
                              <i class="ti-facebook"></i>
                            </a>{" "}
                            <a href="#">
                              <i class="ti-twitter-alt"></i>
                            </a>{" "}
                            <a href="#">
                              <i class="ti-instagram"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item">
                      <div class="img">
                        <a href="team-details.html">
                          <img src="img/team/2.jpg" alt="" />
                        </a>
                      </div>
                      <div class="info">
                        <h6>Emily White</h6>
                        <p>Architect</p>
                        <div class="social valign">
                          <div class="full-width">
                            <p>dipl. Arch FH</p>{" "}
                            <a href="#">
                              <i class="ti-facebook"></i>
                            </a>{" "}
                            <a href="#">
                              <i class="ti-twitter-alt"></i>
                            </a>{" "}
                            <a href="#">
                              <i class="ti-instagram"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item">
                      <div class="img">
                        <a href="team-details.html">
                          <img src="img/team/3.jpg" alt="" />
                        </a>
                      </div>
                      <div class="info">
                        <h6>Enrico Samara</h6>
                        <p>Architect</p>
                        <div class="social valign">
                          <div class="full-width">
                            <p>M.A. FH in Architecture</p>{" "}
                            <a href="#">
                              <i class="ti-facebook"></i>
                            </a>{" "}
                            <a href="#">
                              <i class="ti-twitter-alt"></i>
                            </a>{" "}
                            <a href="#">
                              <i class="ti-instagram"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item">
                      <div class="img">
                        <a href="team-details.html">
                          <img src="img/team/4.jpg" alt="" />
                        </a>
                      </div>
                      <div class="info">
                        <h6>Jesica Smith</h6>
                        <p>Architect</p>
                        <div class="social valign">
                          <div class="full-width">
                            <p>M.A. FH in Architecture</p>{" "}
                            <a href="#">
                              <i class="ti-facebook"></i>
                            </a>{" "}
                            <a href="#">
                              <i class="ti-twitter-alt"></i>
                            </a>{" "}
                            <a href="#">
                              <i class="ti-instagram"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item">
                      <div class="img">
                        <a href="team-details.html">
                          <img src="img/team/5.jpg" alt="" />
                        </a>
                      </div>
                      <div class="info">
                        <h6>Olivia Brown</h6>
                        <p>Architect</p>
                        <div class="social valign">
                          <div class="full-width">
                            <p>dipl. Arch FH</p>{" "}
                            <a href="#">
                              <i class="ti-facebook"></i>
                            </a>{" "}
                            <a href="#">
                              <i class="ti-twitter-alt"></i>
                            </a>{" "}
                            <a href="#">
                              <i class="ti-instagram"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item">
                      <div class="img">
                        <a href="team-details.html">
                          <img src="img/team/6.jpg" alt="" />
                        </a>
                      </div>
                      <div class="info">
                        <h6>Fredia Martin</h6>
                        <p>Architect</p>
                        <div class="social valign">
                          <div class="full-width">
                            <p>M.A. FH in Architecture</p>{" "}
                            <a href="#">
                              <i class="ti-facebook"></i>
                            </a>{" "}
                            <a href="#">
                              <i class="ti-twitter-alt"></i>
                            </a>{" "}
                            <a href="#">
                              <i class="ti-instagram"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              id="blog"
              class="bauen-blog section-padding"
              data-scroll-index="6"
            >
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <h2 class="section-title">
                      Current <span>News</span>
                    </h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="owl-carousel owl-theme">
                      <div class="item">
                        <div class="position-re o-hidden">
                          {" "}
                          <img src="img/slider/1.jpg" alt="" />{" "}
                        </div>
                        <div class="con">
                          <span class="category">
                            <a href="blog.html">Architecture </a> - 20.12.2023
                          </span>
                          <h5>
                            <a href="post.html">
                              Modern Architectural Structures
                            </a>
                          </h5>
                        </div>
                      </div>
                      <div class="item">
                        <div class="position-re o-hidden">
                          {" "}
                          <img src="img/slider/2.jpg" alt="" />{" "}
                        </div>
                        <div class="con">
                          {" "}
                          <span class="category">
                            <a href="blog.html">Interior</a> - 18.12.2023
                          </span>
                          <h5>
                            <a href="post.html">Modernism in Architecture</a>
                          </h5>
                        </div>
                      </div>
                      <div class="item">
                        <div class="position-re o-hidden">
                          {" "}
                          <img src="img/slider/3.jpg" alt="" />{" "}
                        </div>
                        <div class="con">
                          {" "}
                          <span class="category">
                            <a href="blog.html">Urban</a> - 16.12.2023
                          </span>
                          <h5>
                            <a href="post.html">Postmodern Architecture</a>
                          </h5>
                        </div>
                      </div>
                      <div class="item">
                        <div class="position-re o-hidden">
                          {" "}
                          <img src="img/slider/4.jpg" alt="" />{" "}
                        </div>
                        <div class="con">
                          {" "}
                          <span class="category">
                            <a href="blog.html">Planing</a> - 14.12.2023
                          </span>
                          <h5>
                            <a href="post.html">
                              Modern Architecture Buildings
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="contact" class="section-padding" data-scroll-index="7">
              <div class="container">
                <div class="row">
                  <div
                    class="col-md-12 animate-box"
                    data-animate-effect="fadeInUp"
                  >
                    <h2 class="section-title">
                      Contact <span>Us</span>
                    </h2>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-md-4 mb-30 animate-box"
                    data-animate-effect="fadeInUp"
                  >
                    <p>
                      Our firm nisl sodales sit amet sapien idea placerat
                      sodales orcite. Vivamus ne miss rhoncus felis bauen
                      architecture.
                    </p>
                    <p>
                      <b>VAT :</b> USA002323065B06
                    </p>
                  </div>
                  <div
                    class="col-md-4 mb-30 animate-box"
                    data-animate-effect="fadeInUp"
                  >
                    <p>
                      <b>Phone :</b> +1 203-123-0606
                    </p>
                    <p>
                      <b>Email :</b> architecture@bauen.com
                    </p>
                    <p>
                      <b>Address :</b> 24 King St, Charleston, 29401 USA
                    </p>
                  </div>
                  <div
                    class="col-md-4 animate-box"
                    data-animate-effect="fadeInUp"
                  >
                    <form method="post" class="contact__form" action="mail.php">
                      <div class="row">
                        <div class="col-12">
                          <div
                            class="alert alert-success contact__msg"
                            style={{ display: "none" }}
                            role="alert"
                          >
                            Your message was sent successfully.
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <input
                            name="name"
                            type="text"
                            placeholder="Your Name *"
                            required
                          />
                        </div>
                        <div class="col-md-6 form-group">
                          <input
                            name="email"
                            type="email"
                            placeholder="Your Email *"
                            required
                          />
                        </div>
                        <div class="col-md-6 form-group">
                          <input
                            name="phone"
                            type="text"
                            placeholder="Your Number *"
                            required
                          />
                        </div>
                        <div class="col-md-12 form-group">
                          <input
                            name="subject"
                            type="text"
                            placeholder="Subject *"
                            required
                          />
                        </div>
                        <div class="col-md-12 form-group">
                          <textarea
                            name="message"
                            id="message"
                            cols="30"
                            rows="4"
                            placeholder="Message *"
                            required
                          ></textarea>
                        </div>
                        <div class="col-md-12">
                          <input
                            name="submit"
                            type="submit"
                            value="Send Message"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
            <section class="testimonials">
              <div
                class="background bg-img bg-fixed section-padding pb-0"
                data-background="img/banner2.jpg"
                data-overlay-dark="3"
              >
                <div class="container">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="vid-area">
                        <div class="vid-icon">
                          <a
                            class="play-button vid"
                            href="https://youtu.be/RziCmLzpFNY"
                          >
                            <svg class="circle-fill">
                              <circle
                                cx="43"
                                cy="43"
                                r="39"
                                stroke="#fff"
                                stroke-width=".5"
                              ></circle>
                            </svg>
                            <svg class="circle-track">
                              <circle
                                cx="43"
                                cy="43"
                                r="39"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                              ></circle>
                            </svg>{" "}
                            <span class="polygon">
                              <i class="ti-control-play"></i>
                            </span>{" "}
                          </a>
                        </div>
                        <div class="cont mt-15 mb-30">
                          <h5>View promo video</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-5 offset-md-1">
                      <div
                        class="testimonials-box animate-box"
                        data-animate-effect="fadeInUp"
                      >
                        <div class="head-box">
                          <h4>What Client's Say?</h4>
                        </div>
                        <div class="owl-carousel owl-theme">
                          <div class="item">
                            {" "}
                            <span class="quote">
                              <img src="img/quot.png" alt="" />
                            </span>
                            <p>
                              Architect dapibus augue metus the nec feugiat erat
                              hendrerit nec. Duis ve ante the lemon sanleo nec
                              feugiat erat hendrerit necuis ve ante.
                            </p>
                            <div class="info">
                              <div class="author-img">
                                {" "}
                                <img src="img/team/1.jpg" alt="" />{" "}
                              </div>
                              <div class="cont">
                                <h6>Jason Brown</h6>{" "}
                                <span>Crowne Plaza Owner</span>
                              </div>
                            </div>
                          </div>
                          <div class="item">
                            {" "}
                            <span class="quote">
                              <img src="img/quot.png" alt="" />
                            </span>
                            <p>
                              Interior dapibus augue metus the nec feugiat erat
                              hendrerit nec. Duis ve ante the lemon sanleo nec
                              feugiat erat hendrerit necuis ve ante.
                            </p>
                            <div class="info">
                              <div class="author-img">
                                {" "}
                                <img src="img/team/2.jpg" alt="" />{" "}
                              </div>
                              <div class="cont">
                                <h6>Emily White</h6> <span>Armada Owner</span>
                              </div>
                            </div>
                          </div>
                          <div class="item">
                            {" "}
                            <span class="quote">
                              <img src="img/quot.png" alt="" />
                            </span>
                            <p>
                              Urban dapibus augue metus the nec feugiat erat
                              hendrerit nec. Duis ve ante the lemon sanleo nec
                              feugiat erat hendrerit necuis ve ante.
                            </p>
                            <div class="info">
                              <div class="author-img">
                                {" "}
                                <img src="img/team/4.jpg" alt="" />{" "}
                              </div>
                              <div class="cont">
                                <h6>Jesica Smith</h6> <span>Alsa Manager</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="clients">
              <div class="container">
                <div class="row">
                  <div class="col-md-7">
                    <div class="owl-carousel owl-theme">
                      <div class="clients-logo">
                        <a href="#0">
                          <img src="img/clients/1.png" alt="" />
                        </a>
                      </div>
                      <div class="clients-logo">
                        <a href="#0">
                          <img src="img/clients/2.png" alt="" />
                        </a>
                      </div>
                      <div class="clients-logo">
                        <a href="#0">
                          <img src="img/clients/3.png" alt="" />
                        </a>
                      </div>
                      <div class="clients-logo">
                        <a href="#0">
                          <img src="img/clients/4.png" alt="" />
                        </a>
                      </div>
                      <div class="clients-logo">
                        <a href="#0">
                          <img src="img/clients/5.png" alt="" />
                        </a>
                      </div>
                      <div class="clients-logo">
                        <a href="#0">
                          <img src="img/clients/6.png" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <footer class="main-footer dark">
              <div class="container">
                <div class="row">
                  <div class="col-md-4 mb-30">
                    <div class="item fotcont">
                      <div class="fothead">
                        <h6>Phone</h6>
                      </div>
                      <p>+1 203-123-0606</p>
                    </div>
                  </div>
                  <div class="col-md-4 mb-30">
                    <div class="item fotcont">
                      <div class="fothead">
                        <h6>Email</h6>
                      </div>
                      <p>architecture@bauen.com</p>
                    </div>
                  </div>
                  <div class="col-md-4 mb-30">
                    <div class="item fotcont">
                      <div class="fothead">
                        <h6>Our Address</h6>
                      </div>
                      <p>24 King St, Charleston, SC 29401 USA</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sub-footer">
                <div class="container">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="text-left">
                        <p>Â© 2023 Bauen. All right reserved.</p>
                      </div>
                    </div>
                    <div class="col-md-4 abot">
                      <div class="social-icon">
                        {" "}
                        <a href="index.html">
                          <i class="ti-facebook"></i>
                        </a>{" "}
                        <a href="index.html">
                          <i class="ti-twitter"></i>
                        </a>{" "}
                        <a href="index.html">
                          <i class="ti-instagram"></i>
                        </a>{" "}
                        <a href="index.html">
                          <i class="ti-pinterest"></i>
                        </a>{" "}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <p class="right">
                        <a href="#">Terms &amp; Conditions</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </body>
      </html>
    </div>
  );
}
