import React from "react";
import Logo from "../../img/logo.svg";
import LogoZlatni from "../../img/logo-zlatni.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fa } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-4">
            <div className="logo wow fadeInUp" data-wow-delay="0.3s">
              <img src={LogoZlatni} alt="Image" />
            </div>
            {/*<!-- end Logo -->*/}
            <div className="footer-info wow fadeInUp" data-wow-delay="0.4s">
              <p>
                <FontAwesomeIcon icon={faPhone} />
                &nbsp;&nbsp;&nbsp;+382 69/036-037
              </p>
              <br />
              <div className="bosluk04"></div>
              <p>
                <FontAwesomeIcon icon={faEnvelope} />
                &nbsp;&nbsp;&nbsp;office@meddis.me
              </p>
              <br />
              <p>
                <FontAwesomeIcon icon={faLocationDot} />
                &nbsp;&nbsp;&nbsp; Bar, Crna Gora
              </p>
            </div>
            {/*<!-- end footer-info -->*/}
            <ul className="footer-social wow fadeInUp" data-wow-delay="0.5s">
              <li>
                <a href="#">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li>
              <li>
                <a href="#">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li>
                <a href="#">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </li>
            </ul>
          </div>
          {/*<!-- end col-3 -->*/}
          <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.6s">
            <h6 className="widget-title">Prijavite se na naš Newsletter</h6>
            <p className="footerp">
              Potpišite se kako bi bili informinasi o našim uslugama i
              projektima.
            </p>
            <div className="row">
              <form action="subscribe.php" className="form__grup" method="post">
                <div className="col-sm">
                  <div
                    className="form__grup wow fadeInLeft"
                    data-wow-delay="0.7s"
                  >
                    <input
                      type="email"
                      className="form-popup__input"
                      placeholder="Vaša Email adresa"
                      id="email8"
                      name="email8"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div
                    className="form__grup wow fadeInUp"
                    data-wow-delay="0.9s"
                  >
                    <button className="custom-button-form">Potpiši se</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/*<!-- end col-4 -->*/}
          <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <h6 className="widget-title">Oblasti djelovanja</h6>
            <ul className="footer-menu">
              <li>
                <a href="steel-industry.html">Visokogradnja</a>
              </li>
              <li>
                <a href="energy-industry.html">Rekonstrukcija</a>
              </li>
              <li>
                <a href="logistics-industry.html">Niskogradnja</a>
              </li>
            </ul>
          </div>
          {/*<!-- end col-3 -->*/}

          {/*<!-- end col-2 -->*/}
        </div>
        {/*<!-- end swiper-reow -->*/}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 wow fadeInUp" data-wow-delay="0.9s">
            <p className="copyright">
              © 2022 <a href="https://digital-artefakt.me"> Digital Artefakt</a>{" "}
              - All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
      <div id="top" style={{ cursor: "pointer" }}>
        <img width="50" height="50" src="img/go-top.png" alt="" />
      </div>
    </footer>
  );
}
