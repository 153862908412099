import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
  faFacebook,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";

export default function Kontakt() {
  return (
    <>
      <main>
        <section className="iletisim-icon-alani">
          <div className="tablo">
            <div className="tablo--1-ve-3 wow fadeInLeft" data-wow-delay="0.3s">
              <div
                className="ozellik-kutu-iletisim"
                onclick="location.href='tel:02125376252';"
                style={{ cursor: "pointer" }}
                data-tilt
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <h3 className="baslik-4 h-yazi-margin-kucuk-2">
                  Kontakt telefon centrala
                </h3>
                <p className="ozellik-kutu-iletisim--yazi">+382 69-062-361</p>
              </div>
            </div>
            <div className="tablo--1-ve-3 wow fadeInLeft" data-wow-delay="0.4s">
              <div
                className="ozellik-kutu-iletisim"
                onclick="location.href='mailto:office@meddis.me';"
                style={{ cursor: "pointer" }}
                data-tilt
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <h3 className="baslik-4 h-yazi-margin-kucuk-2">
                  E-mail adresa
                </h3>
                <p className="ozellik-kutu-iletisim--yazi">office@meddis.me</p>
              </div>
            </div>
            <div
              className="tablo--1-ve-3 wow fadeInRight"
              data-wow-delay="0.5s"
            >
              <div
                className="ozellik-kutu-iletisim"
                onclick="location.href='https://goo.gl/maps/dSGtD7pcer4zp4Q56';"
                style={{ cursor: "pointer" }}
                data-tilt
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <h3 className="baslik-4 h-yazi-margin-kucuk-2">Kancelarija</h3>
                <p className="ozellik-kutu-iletisim--yazi">
                  Bulevar Revolucije C-7, Bar, Crna Gora​
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="iletisim-form-alani2">
          <div className="container">
            <form action="contactform.php" className="form" method="post">
              <div className="row">
                <div className="col-sm">
                  <div
                    className="form__grup wow fadeInLeft"
                    data-wow-delay="0.4s"
                  >
                    <input
                      type="text"
                      className="form__input"
                      placeholder="Ime"
                      id="txt_isim"
                      name="name"
                      required
                    />
                    <label for="name" className="form__label">
                      Ime
                    </label>
                  </div>
                </div>
                <div className="col-sm">
                  <div
                    className="form__grup wow fadeInLeft"
                    data-wow-delay="0.5s"
                  >
                    <input
                      type="email"
                      className="form__input"
                      placeholder="E-mail adresa"
                      id="txt_eposta"
                      name="email"
                      required
                    />
                    <label for="email" className="form__label">
                      E-mail adresa
                    </label>
                  </div>
                </div>
                <div className="col-sm">
                  <div
                    className="form__grup wow fadeInRight"
                    data-wow-delay="0.6s"
                  >
                    <input
                      type="text"
                      className="form__input"
                      placeholder="Kontakt telefon"
                      id="txt_telefon"
                      name="telefon"
                      required
                    />
                    <label for="telefon" className="form__label">
                      Kontakt telefon
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="container">
            <div className="row">
              <div className="form__grup wow fadeInUp" data-wow-delay="0.7s">
                <textarea
                  name="message"
                  placeholder="Vaša poruka"
                  id="txt_mesaj"
                  className="form__input"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-sm">
                <div className="form__grup wow fadeInUp" data-wow-delay="0.8s">
                  <button
                    className="buton-contact buton-contact--kirmizi"
                    id="btn_Gonder"
                    style={{ marginTop: "5rem" }}
                  >
                    Pošalji
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div style={{ width: "100%" }}>
          <iframe
            width="100%"
            height="600"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Bulevar%20Revolucije%20C-7,%20Bar,%20Crna%20Gora%E2%80%8B+(MEDDiS)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          >
            <a href="https://www.maps.ie/distance-area-calculator.html">
              measure area map
            </a>
          </iframe>
        </div>
      </main>
    </>
  );
}
