import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import store from './store'
import { render } from 'react-snapshot';
import { Provider } from 'react-redux'
import { Provider as Aaa} from "react-alert";
import './style.css';

import AlertMUITemplate from "react-alert-template-mui";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <head>
    <link rel="stylesheet"
  href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
  integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
  crossorigin="anonymous"/>

<link rel="preconnect" href="https://fonts.googleapis.com"/>
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
<link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Roboto&display=swap" rel="stylesheet"/></head>
      <Provider store={store}>
<Aaa template={AlertMUITemplate}>
    <App />
    </Aaa>

    </Provider>
  </React.StrictMode>

);

