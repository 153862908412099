import divider from "../../images/divider.jpg";
import about from "../../images/industen-about.png";
import mehanizam from "../../images/technology.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sat from "../../img/sat.svg";
import odgovornost from "../../img/odgovornost.svg";
import kvalitet from "../../img/kvalitet.svg";
import { faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
export default function Onama() {
  return (
    <main>
      <div className="bosluk8"></div>
      <section className="hakkimizda-bolumu-anasayfa">
        <div className="h-yazi-ozel h-yazi-margin-ozel"></div>
        <div className="tablo">
          <div className="tablo--1-ve-2 ">
            <div className="galeri1" data-wow-delay="0.5s">
              <img className="imagerotate" src={mehanizam} alt="" />
            </div>
            <div
              className="galeri "
              data-wow-delay="100ms"
              data-wow-duration="1500ms"
            >
              <img
                src={about}
                alt="Industen About"
                className="galeri__gorsel galeri__gorsel--3 zimage"
              />
            </div>
            <div className="countt wow fadeInUp" data-wow-delay="1s">
              <div className="counter-box ">
                <span className="odometer" data-count="24" data-status="yes">
                  24
                </span>
                <h6>Godine iskustva</h6>
              </div>
            </div>
            <div className="countb wow fadeInLeft" data-wow-delay="1s">
              <div className="counter-box">
                <span className="odometer" data-count="240" data-status="yes">
                  45
                </span>
                <h6>Ljudi u timu</h6>
              </div>
            </div>
          </div>
          <div className="tablo--1-ve-3 wow fadeInUp" data-wow-delay="0.5s">
            <h2 className="h2-baslik-anasayfa-ozel" data-wow-delay="0.5s">
              {" "}
              MEDDiS GROUP{" "}
            </h2>
            <div className="bosluk333"></div>
            <p className="paragraf">
              Preduzeće MEDDIS GROUP osnovano je 1998. godine u Baru. Osnovna
              djelatnost preduzeća je građevinarstvo - projektovanje, izgradnja
              i nadzor objekata, i u manjem obimu pružanje usluga drugim pravnim
              licima. Preduzeće je u isto vrijeme investitor i izvođač svojih
              objekata, (porodične kuće, luksuzne vile, stanovi, apartmani,
              hoteli, poslovni prostori, skladišni terminali i sl.). MEDDIS
              GROUP je od dana osnivanja do danas izgradila i na tržištu
              realizovala više hiljada poslovno-stambenih jedinica i drugih
              građevinskih objekata. Preduzeće uživa veliki ugled u svojoj
              branši u Crnoj Gori, svrstavajući se u sam vrh kada su domaće
              kompanije u pitanju. <br />
              Ključno obilježje MEDDiS GROUP je realizacija po ugovoru “ključ u
              ruke”, to jest koncepta projektovanja i izgradnje gdje investitor
              angažuje kompaniju od samog početka do završetka projekta i
              njegove potpune funkcionalnosti.Na taj način preuzimamo
              odgovornost za kompletnu investiciju u svim njenim fazama.
            </p>
            <div className="bosluk333"></div>
            <img
              className="divider"
              width="120"
              height="15"
              title="divider"
              alt="divider"
              src={divider}
            />
            <div className="bosluk333"></div>

            <div className="bosluk1"></div>

            <div className="bosluk1"></div>

            <div className="bosluk1"></div>

            <div className="bosluk333"></div>
            <a href="about-us.html" className="custom-button">
              Pogledajte naše projekte
            </a>
            <div className="bosluk3rh"></div>
          </div>
        </div>
      </section>
      <div className="bosluk9pps"></div>

      <section className="markalar wow fadeInUp" data-wow-delay="0.3s">
        <h6>.</h6>
        <div className="h-yazi-ortalama h-yazi-margin-kucuk-21 wow fade">
          <img src="img/client11.png" alt="Marka 1" className="marka" />
          <img src="img/client22.png" alt="Marka 2" className="marka" />
          <img src="img/client33.png" alt="Marka 3" className="marka" />
          <img src="img/client44.png" alt="Marka 4" className="marka" />
          <img src="img/client55.png" alt="Marka 5" className="marka" />
        </div>
      </section>
      <section
        className="ozellika"
        data-wow-delay="0.2s"
        data-background="#dae0e9"
      >
        <div className="container-fluid">
          <div className="row align-items-center no-gutters">
            <div className="col-lg-12">
              <div className="wow " data-wow-delay="0.3s">
                <div className="boslukalt"></div>
                <h2 className="h2-baslik-hizmetler-2 ">Tim za uspjeh </h2>
                <p className="h2-baslik-hizmetler-2__paragraf">
                  Gradimo vašu budućnost
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="team-section">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12">
              <Carousel responsive={responsive}>
                <div className="swiper-slide">
                  <div className="className-box">
                    <div
                      className="services-kutu2 wow fadeInLeft"
                      data-wow-delay="0.4s"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="member-box wow reveal-effect">
                        <figure>
                          {" "}
                          <img
                            src={require("../../img/IMG-9418.JPG")}
                            alt="Image"
                          />
                          <figcaption>
                            <h6>Munib Ličina</h6>
                            <p className="paragraf-sol-beyaz-orta">
                              Glavni izvršni direktor i osnivač
                            </p>
                            <ul>
                              <li>
                                <a href="#">
                                  <i className="flaticon-facebook"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="flaticon-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="flaticon-twitter"></i>
                                </a>
                              </li>
                            </ul>
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="className-box">
                    <div
                      className="services-kutu2 wow fadeInLeft"
                      data-wow-delay="0.5s"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="member-box wow reveal-effect">
                        <figure>
                          {" "}
                          <img
                            src={require("../../img/IMG-9417.JPG")}
                            alt="Image"
                          />
                          <figcaption>
                            <h6>Dino Ličina</h6>
                            <p className="paragraf-sol-beyaz-orta">
                              Inženjer arhitekture - Projektant <br />
                              <br />
                              Rođen je 1992. godine u Kotoru. Diplomirao je na
                              Internacionalnom Burch Univerzitetu u Sarajevu, na
                              temu poslovni objekat – unutrašnje uređenje, gdje
                              je završio magistarske studije. Još kao student
                              učestvovao je na mnogim izložbama fakulteta, a
                              iskustvo stiče kroz samostalno projektovanje
                              manjih rezidencijalnih objekata i enterijera. Član
                              je Meddis group tima od 2022 godine.
                            </p>
                            <ul>
                              <li>
                                <a href="#">
                                  <i className="flaticon-facebook"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="flaticon-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="flaticon-twitter"></i>
                                </a>
                              </li>
                            </ul>
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="className-box">
                    <div
                      className="services-kutu2 wow fadeInLeft"
                      data-wow-delay="0.6s"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="member-box wow reveal-effect">
                        <figure>
                          {" "}
                          <img src={require("../../img/IMG-9416.JPG")} />
                          <figcaption>
                            <h6>Sanela Ličina</h6>
                            <p className="paragraf-sol-beyaz-orta">
                              IZVRŠNI DIREKTOR
                              <br />
                              <br />
                              <ol
                                className="lista-bio"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                Radno iskustvo:
                                <br />
                                <li>
                                  Pravni zastupnik u Uprava za šume, Pljevlja
                                  2004 - 2012
                                </li>
                                <li>
                                  SL menadžer u Safe invest Holding AG. Beč,
                                  Austrija, Podgorica 2007 – 2012 god
                                </li>
                                <li>
                                  Izvršni direktor u Safe invest Holding AG. Beč
                                  – Austrija, Podgorica. 2012 – 2016 godine -{" "}
                                  Potpredsjednica Opštine Bar 2016 – 2019 god.
                                </li>
                                <li>
                                  Izvršni direktor u MEDDiS group D.O.O od 2020.
                                  Stručni saradnik u Regionalnoj Komisiji za
                                  povraćaj i obeštećenje Bar
                                </li>
                              </ol>
                            </p>
                            <ul>
                              <li>
                                <a href="#">
                                  <i className="flaticon-facebook"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="flaticon-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="flaticon-twitter"></i>
                                </a>
                              </li>
                            </ul>
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>

            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
    </main>
  );
}
