import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { LineWave } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../img/Logo1.svg";

import { ucitajProjekte } from "../../projekat-redux/sviProjekti";
function Projekti() {
  const reduxProjekti = useSelector((state) => state.projekti);
  const state = useSelector((state) => state);

  useEffect(() => {
    console.log(reduxProjekti);
  }, []);
  const [loader, postaviLoader] = useState(true);
  useEffect(() => {
    console.log(state);
    if (reduxProjekti.niz.length != 0) {
      postaviLoader(false);
    }
  });
  return (
    <>
      {/*Projects Alanı*/}
      <main>
        {/*Project Alanı*/}
        <div className="bosluk3"></div>
        <div className="h-yazi-ortalama h-yazi-margin-orta-4 wow fadeInUp"></div>
        <div>
          <div className="component-systemTabs">
            <div className="tabs-container">
              {/*Project Tab1*/}
              {loader == true ? (
                <>
                  <LineWave
                    className="xxx"
                    height="200"
                    width="200"
                    color="#4fa94d"
                    ariaLabel="line-wave"
                    visible={true}
                  />
                </>
              ) : (
                <div className="row  reddd">
                  {reduxProjekti.niz.map((projekat) => (
                    <div
                      className="col-lg-4 col-sm-12"
                      style={{ padding: 0 }}
                      onclick="window.location.href='project-detail.html'"
                    >
                      <img className="slicica ssss" src={Logo} />

                      <Link
                        to={`/projekti/${projekat.naziv.split(" ").join("-")}`}
                      >
                        <img
                          style={{ padding: 15 }}
                          src={projekat.odabrana_slika}
                          alt="Project"
                        />
                        <div>
                          <div className="cardContent sadrzaj-kartica">
                            <h2 style={{ color: "white" }}>{projekat.naziv}</h2>
                            <button
                              class="projekat-dugmence"
                              onclick="window.location.href='project-detail.html'"
                            >
                              Pogledajte projekat
                            </button>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              )}

              {/*Project Tab2*/}

              {/*Project Tab3*/}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export { Projekti };
