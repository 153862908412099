import { configureStore } from '@reduxjs/toolkit';
import korisnikReducer from './autentikacija-redux/korisnik/korisnik_slice';
import projekatReducer from  './projekat-redux/dodajProjekat';
import projektiReducer from  './projekat-redux/sviProjekti';
import clanakReducer from  './clanci-redux/dodajClanak';
import clanciReducer from  './clanci-redux/sviClanci';
export default configureStore({
  reducer: { korisnik: korisnikReducer,
    projekat: projekatReducer,
    projekti:projektiReducer,
    clanak:clanakReducer,
    clanci:clanciReducer,

  },
})