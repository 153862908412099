import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { prijava } from "../autentikacija-redux/korisnik/korisnik_slice.js";
function Prijava() {
  const korisnik = useSelector((state) => state.korisnik);
  const dispatch = useDispatch();
  const [prikazi, postaviPrikazano] = useState("none");
  const navigacija = useNavigate();
  const [unos, postaviUnos] = useState({
    email: "",
    sifra: "",
  });
  const handleChange = (e) => {
    postaviUnos((postojeci) => ({
      ...postojeci,
      [e.target.name]: e.target.value,
    }));
  };
  console.log(unos);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      localStorage.removeItem("korisnik");

      const odgovor = await axios.post("https://white-homes.me/prijava", {
        unos,
      });
      if (odgovor.data == "korisnik nije pronadjen") {
        postaviPrikazano("block");
        console.log(odgovor);
      } else {
        postaviPrikazano("none");
        localStorage.setItem("korisnik", JSON.stringify(odgovor.data[0]));
        await dispatch(prijava(JSON.stringify(odgovor.data[0])));
        navigacija("/administrator");
        console.log(korisnik);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => console.log(korisnik), [korisnik]);
  return (
    <div className="auth">
      <h1>prijava</h1>
      <form>
        <input
          onChange={handleChange}
          type="text"
          name="email"
          placeholder="email"
        />
        <input
          onChange={handleChange}
          type="password"
          name="sifra"
          placeholder="password"
        />
        <button onClick={handleSubmit}>prijava</button>
        <span>
          Još uvijek nemate nalog{" "}
          <Link to="/prijava">Registrujte se ovdje</Link>
        </span>
      </form>
    </div>
  );
}
export default Prijava;
