import { createSlice } from '@reduxjs/toolkit'

export const projektiSlice = createSlice({
  name: 'projekti',
  initialState:{niz:[]},

  reducers: {
    ucitajProjekte: (state,projekti) => {
        console.log('reducer pozvan')

        state.niz=[...projekti.payload]
    },

  },
})

// Action creators are generated for each case reducer function
export const { ucitajProjekte} = projektiSlice.actions;

export default projektiSlice.reducer;
