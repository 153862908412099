import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ucitajClanke } from "../../clanci-redux/sviClanci";
import parse from "html-react-parser";
export function Clanak() {
  let clanciRedux = useSelector((state) => state.clanci.niz);
  let [clanakk, postaviClanak] = useState(null);
  let clanak = () => {
    console.log(naziv);

    return clanciRedux.filter(
      (clanak) =>
        clanak.naziv.split(" ").join("-").toLowerCase() == naziv.toLowerCase()
    );
  };
  let naziv = decodeURI(document.location.pathname)
    .split("/")
    [decodeURI(document.location.pathname).split("/").length - 1].split("%20")
    .join("-");
  let dispatch = useDispatch();
  const ucitajClanke = async () => {
    return await axios.get("https://white-homes.me/clanci");
  };
  useEffect(() => {
    clanciRedux.length == 0
      ? ucitajClanke().then((x) => console.log(x.data))
      : console.log(clanciRedux);
  }, []);
  useEffect(() => {
    console.log(clanciRedux);
    postaviClanak(clanak()[0]);
    console.log(clanakk);
    console.log();
  }, [clanciRedux]);

  return (
    <>
      <div class="container">
        <div class="row">
          {clanakk != null ? (
            <div class="col-sm-8">
              <div class="bosluk333"></div>
              <div class="galeri">
                <img
                  src={clanakk.odabrana_slika}
                  alt="Finance What is Concordat?"
                  style={{ marginTop: 0 }}
                  class="galeri__gorsel galeri__gorsel--3"
                />
              </div>
              <div class="bosluk3"></div>
              <h2 class="h2-baslik-anasayfa-ozel-blog ">{clanakk.naziv}</h2>
              <div class="bosluk333"></div>
              <p class="paragraf  ">{parse(clanakk.tekst)}</p>
              <div class="bosluk333"></div>
              <div class="bosluk3"></div>
            </div>
          ) : null}

          <div class="col-sm-4">
            <div class="sidebar-blog">
              <h2 class="h2-baslik-anasayfa-blog wow fade"> Kategorije</h2>
              <span class="menu-blog">
                <a href="#" class="menucolor">
                  Izgradnja{" "}
                </a>
              </span>
              <span class="menu-blog">
                <a href="#" class="menucolor">
                  Projektovanje{" "}
                </a>
              </span>
              <span class="menu-blog">
                <a href="#" class="menucolor">
                  Arhitektura{" "}
                </a>
              </span>
            </div>
            <div class="bosluk3"></div>
          </div>
        </div>
      </div>
    </>
  );
}
