import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../img/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { ucitajClanke } from "../../clanci-redux/sviClanci";

import { ucitajProjekte } from "../../projekat-redux/sviProjekti";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
  faFacebook,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  const [hash, postaviHash] = useState("");
  const [x, y] = useState(false);
  const [z, d] = useState(false);

  let [sidebar, postaviSidebar] = useState(false);
  let projekti = async () => {
    return await axios.get("https://white-homes.me/projekti");
  };
  let clanci = async () => {
    return await axios.get("https://white-homes.me/clanci");
  };

  const dispatch = useDispatch();
  function skrol() {
    document.getElementById("Izgradnja").scrollIntoView({ behavior: "smooth" });
  }
  useEffect(() => {
    if (!x) {
      //console.log("iteracija");
      projekti().then((uspjeh, greska) => {
        if (uspjeh) {
          console.log(uspjeh);
          let niz = uspjeh.data.filter((x) => x.izgradnja == 1);
          console.log(niz);
          dispatch(ucitajProjekte([...niz]));
          y(true);
        } else {
          console.log(greska);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (!z) {
      //console.log("iteracija");
      clanci().then((uspjeh, greska) => {
        if (uspjeh) {
          console.log(uspjeh);
          let niz = uspjeh.data.filter((x) => x.izgradnja == 1);
          console.log(niz.data);
          dispatch(ucitajClanke([...niz]));
          d(true);
        } else {
          console.log(greska);
        }
      });
    }
  }, []);

  return (
    <>
      <aside
        style={sidebar == true ? { left: "0%" } : { left: "-100%" }}
        className="side-widget"
      >
        <div className="inner">
          <div className="logo">
            {" "}
            <a href="index.html">
              <img src={Logo} />
            </a>{" "}
          </div>
          <div className="hide-mobile">
            <h6 className="widget-title">CONTACT INFO</h6>
            <address className="address">
              <p>
                +1 (234) 567 89 10
                <br />
                <a href="#">example@example.com</a>
              </p>
            </address>
          </div>
          <div className="show-mobile">
            <div className="site-menu">
              <ul className="menu">
                <li>
                  <Link to="/">Naslovna</Link>
                </li>
                <li>
                  <Link to="/">Izgradnja</Link>
                </li>
                <li>
                  <Link to="/projekti">Projekti</Link>
                </li>
                <li>
                  <Link to="/o-nama">O nama</Link>
                </li>
                <li>
                  <Link to="/clanci">Članci</Link>
                </li>
                <li>
                  <Link to="/kontakt">Kontakt</Link>
                </li>
              </ul>
            </div>
          </div>
          <small>Â© 2022 - Industen</small>{" "}
        </div>
      </aside>
      <nav className="navbar">
        <div className="container-fluid">
          <div className="logo">
            {" "}
            <a href="index.html">
              <img src={Logo} />
            </a>{" "}
          </div>
          <div className="site-menu">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <a href="#" className="iconrenk">
                    <i className="flaticon-headphones icontop"></i>
                    <FontAwesomeIcon
                      className="ics"
                      icon={faPhone}
                    ></FontAwesomeIcon>
                    <div className="idl">
                      <strong>Kontakt telefon</strong>
                      <br />
                      +382 (68) 036 037
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a href="#" className="iconrenk">
                    <i className="flaticon-trip icontop"></i>
                    <FontAwesomeIcon
                      className="ics"
                      icon={faLocationDot}
                    ></FontAwesomeIcon>
                    <div className="idl2">
                      <strong>Bar</strong>
                      <br />
                      Bulevar Revolucije,C-7
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a href="#" className="iconrenk">
                    <i className="flaticon-email icontop1"></i>
                    <FontAwesomeIcon
                      className="ics"
                      icon={faEnvelope}
                    ></FontAwesomeIcon>
                    <div className="idl3">
                      <strong>Kontakt Email</strong>
                      <br />
                      office@meddis-group.me
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <ul className="menueffect">
              <li>
                <Link to="/">Naslovna</Link>
              </li>
              <li>
                <Link to="/">Izgradnja</Link>
              </li>
              <li>
                <Link to="/projekti">Projekti</Link>
              </li>
              <li>
                <Link to="/o-nama">O nama</Link>
              </li>
              <li>
                <Link to="/clanci">Članci</Link>
              </li>
              <li>
                <Link to="/kontakt">Kontakt</Link>
              </li>
            </ul>
          </div>
          <div
            onClick={() => {
              postaviSidebar(!sidebar);
            }}
            className="hamburger-menu"
          >
            {" "}
            <span></span> <span></span> <span></span>{" "}
          </div>
          <div className="navbar-button">
            {" "}
            <a href="#">
              <FontAwesomeIcon icon={faMessage}> </FontAwesomeIcon>
              &nbsp;&nbsp;&nbsp;Pošaljite upit
            </a>{" "}
          </div>
        </div>
      </nav>
    </>
  );
}
